import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../Actions/auth";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LoginPop = ({ login, isAuthenticated, onClose, className }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate(); // Hook for navigation

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    // This useEffect will trigger when isAuthenticated changes
    if (isAuthenticated) {
      onClose(); // Close the menu when the user is authenticated
      
    }
  }, [isAuthenticated]);

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      await login(email, password);
    } catch (error) {
      console.error("Login failed:", error);
    } finally {
      // Check if this log statement is reached
      
      onClose(); // Ensure that onClose is called here
    }
  };

  return (
    <form
      className={`flex flex-col p-8 bg-white rounded border border-solid shadow-2xl border-[color:var(--Gray-100,#E4E7E9)] max-w-[424px] ${className}`}
      onSubmit={(e) => onSubmit(e)}
    >
      <header className="text-xl font-semibold leading-7 text-center whitespace-nowrap text-zinc-900">
        Войти в аккаунт
      </header>
      <label
        className="mt-5 text-sm leading-5 whitespace-nowrap text-zinc-900"
      >
        Email адресс
      </label>
      <input
        type="email"
        name={"email"}
        value={email}
        placeholder={"Email"}
        className="shrink-0 mt-2 h-11 bg-white rounded-sm border border-solid border-[color:var(--Gray-100,#E4E7E9)] outline-none p-2"
        onChange={(e) => onChange(e)}
        required
      />
      <div className="flex gap-5 justify-between mt-4 text-sm leading-5">
        <div className="text-zinc-900">Пароль</div>

        <Link to={"/reset-password"} className="font-medium text-sky-400">
          Забыли пароль?
        </Link>
      </div>
      <div className="flex flex-row">
        <input
          type={showPassword ? "text" : "password"}
          name={"password"}
          value={password}
          placeholder={"Password"}
          className="shrink-0 mt-2 h-11 bg-white rounded-sm border border-solid border-[color:var(--Gray-100,#E4E7E9)] outline-none p-2 grow"
          onChange={(e) => onChange(e)}
          required
        />
        <button
          onClick={handleShowPassword}
          className="shrink-0 mt-2 h-11 bg-white rounded-sm border border-solid border-[color:var(--Gray-100,#E4E7E9)] outline-none p-2"
        >
          <FontAwesomeIcon
            icon={faEye}
            className={`text-${showPassword ? "orange" : "gray"}-400`}
          />
        </button>
      </div>
      <button
        type={"submit"}
        className="flex gap-2 justify-center self-start px-6 mt-5 text-sm font-bold tracking-normal leading-10 text-white uppercase whitespace-nowrap bg-orange-400 rounded-sm"
      >
        <span className="grow">ЛОГИН</span>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2c2149ae3c405dce61079c18695dad90fa1ed8e56693380a2c000152af016002?apiKey=49819ad3d25f4b5f837dad19f5421852&"
          className="my-auto w-5 aspect-square"
          alt="Arrow"
        />
      </button>
      <div className="flex justify-center items-center mt-6 text-sm leading-5 whitespace-nowrap text-slate-500">
        <div className="border border-black w-full"></div>
        <div className="justify-center px-2 bg-white">У Вас нет аккаунта?</div>
        <div className="border border-black w-full"></div>
      </div>
      <Link
        to={"/sign-up"}
        className="justify-center items-center px-16 mt-3 text-sm font-bold tracking-normal leading-10 text-orange-400 uppercase whitespace-nowrap rounded-sm border-2 border-solid border-[color:var(--Primary-100,#FFE7D6)]"
      >
        СОЗДАТЬ АККАУНТ
      </Link>
    </form>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(LoginPop);
