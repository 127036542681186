import { useParams } from "react-router";
import useFetch from "../Components/useFetch";
import Delete from "../Components/Delete";

const BlogDetail = () => {
  const { id } = useParams();
  const {
    data: hotels,
    isLoading,
    error,
  } = useFetch(`${process.env.REACT_APP_API_URL}/api/v1/hotels/` + id + "/");
  return (
    <div className="hotels-details">
      {error && <div>{error}</div>}
      {isLoading && (
        <div className="loading-preview">
          <div id="loader"></div>
          <h2>Loading...</h2>
        </div>
      )}
      {hotels && (
        <article>
          <div className="hotels-details-title">
            <div className="hotels-title">
              <h2>{hotels.base_hotel.name}</h2>
            </div>
            <div className="hotels-button">
              <button className="button-24" onClick={() => Delete(id)}>
                Delete
              </button>
            </div>
          </div>
          <p>{hotels.author}</p>
          <div>{hotels.body}</div>
        </article>
      )}
    </div>
  );
};

export default BlogDetail;
