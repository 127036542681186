// import React, { useRef, useState } from 'react';
// // Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { Link } from "react-router-dom";
import StarRating from "./StarRating";

// // Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/pagination';

// // import required modules
import { Pagination } from "swiper/modules";

// export default function App() {
//   return (
//     <>
//       <Swiper
//         slidesPerView={3}
//         spaceBetween={30}
//         pagination={{
//           clickable: true,
//         }}
//         modules={[Pagination]}
//         className="mySwiper"
//       >
//         <SwiperSlide>Slide 1</SwiperSlide>
//         <SwiperSlide>Slide 2</SwiperSlide>
//         <SwiperSlide>Slide 3</SwiperSlide>
//         <SwiperSlide>Slide 4</SwiperSlide>
//         <SwiperSlide>Slide 5</SwiperSlide>
//         <SwiperSlide>Slide 6</SwiperSlide>
//         <SwiperSlide>Slide 7</SwiperSlide>
//         <SwiperSlide>Slide 8</SwiperSlide>
//         <SwiperSlide>Slide 9</SwiperSlide>
//       </Swiper>
//     </>
//   );
// }
import * as React from "react";
import PopularDestinations from "../ProductList/ProductList";

const MAX_DESCRIPTION_LENGTH = 200;
const BlogList = (/*props or*/ { blogs: hotels, title }) => {
  const truncateDescription = (description) => {
    if (description.length > MAX_DESCRIPTION_LENGTH) {
      return `${description.substring(0, MAX_DESCRIPTION_LENGTH)}...`;
    }
    return description;
  };
  return (
    <div className="flex flex-col items-center px-16 pb-10 w-full max-md:px-5 max-md:max-w-full pt-20">
      <div className="flex flex-col -mb-px w-full max-w-[1440px] max-md:max-w-full">
        <div className="flex gap-1.5 justify-between items-center max-md:flex-wrap max-md:max-w-full">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4a810841c6fd42652b272e8f99000039d86c918c4ec34d4f55f8946d62ac839b?apiKey=49819ad3d25f4b5f837dad19f5421852&"
            className="self-stretch my-auto aspect-square w-[35px]"
          />
          <div className="flex flex-col flex-1 self-stretch max-md:max-w-full">
            <div className="flex gap-0 justify-between items-start pb-12 max-md:flex-wrap max-md:max-w-full">
              <div className="grow text-3xl font-semibold leading-10 text-neutral-900 max-md:max-w-full">
                Советы и рекомендации по путешествиям
              </div>
            </div>
            <div className="max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
                <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                  <div className="grow max-md:mt-8 max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
                      <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c8c858447f6da094720745289b505a1aa8e97fbc4f0b0f08dcb504b385f1b08c?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c8c858447f6da094720745289b505a1aa8e97fbc4f0b0f08dcb504b385f1b08c?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c8c858447f6da094720745289b505a1aa8e97fbc4f0b0f08dcb504b385f1b08c?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c8c858447f6da094720745289b505a1aa8e97fbc4f0b0f08dcb504b385f1b08c?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c8c858447f6da094720745289b505a1aa8e97fbc4f0b0f08dcb504b385f1b08c?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c8c858447f6da094720745289b505a1aa8e97fbc4f0b0f08dcb504b385f1b08c?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c8c858447f6da094720745289b505a1aa8e97fbc4f0b0f08dcb504b385f1b08c?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c8c858447f6da094720745289b505a1aa8e97fbc4f0b0f08dcb504b385f1b08c?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                          className="grow w-full aspect-[0.83]"
                        />
                      </div>
                      <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                        <div className="flex flex-col grow self-stretch pl-8 text-neutral-400">
                          <div className="text-2xl font-semibold leading-9 text-neutral-900">
                            Поход за мороженым в Ист-Виллидж
                            <br />
                          </div>
                          <div className="text-base leading-6">
                            Во время этой полуторачасовой экскурсии длиной 1,5
                            мили мы остановимся в пяти разных магазинах
                            мороженого мирового класса. В каждом магазине
                            мороженого мы узнаем историю его создания и увидим,
                            что делает мороженое уникальным, пока вы будете
                            наслаждаться каждым...
                          </div>
                          <div className="flex gap-5 justify-between pr-20 mt-6 text-xs leading-4 whitespace-nowrap max-md:pr-5">
                            <div className="flex gap-1 justify-between">
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/71781e48210dfbeb03f1afd3d104238aa39f4633776a5a69a06d515bcb2b2761?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                                className="justify-center items-center w-4 aspect-square"
                              />
                              <div className="grow">Сегодня</div>
                            </div>
                            <div className="flex gap-1 justify-between">
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/93a9be14daf4bc73c8ecdc08f87f8300ffd78b40bf8443aa1823544c64e97de4?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                                className="justify-center items-center w-4 aspect-square"
                              />
                              <div className="grow">Maria Philips</div>
                            </div>
                            <div className="flex gap-1 justify-between">
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/b578fa23b18d8ec685b348bcdf3af971fa4dad47b10fdccab422e9e0ba348a9f?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                                className="justify-center items-center w-4 aspect-square"
                              />
                              <div>2</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                  <div className="grow max-md:mt-8 max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
                      <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/bdb96b9c9dcceab647b4c98e8321c21095f85a9d4304884e02e2095fcae2fa45?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/bdb96b9c9dcceab647b4c98e8321c21095f85a9d4304884e02e2095fcae2fa45?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bdb96b9c9dcceab647b4c98e8321c21095f85a9d4304884e02e2095fcae2fa45?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/bdb96b9c9dcceab647b4c98e8321c21095f85a9d4304884e02e2095fcae2fa45?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/bdb96b9c9dcceab647b4c98e8321c21095f85a9d4304884e02e2095fcae2fa45?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bdb96b9c9dcceab647b4c98e8321c21095f85a9d4304884e02e2095fcae2fa45?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/bdb96b9c9dcceab647b4c98e8321c21095f85a9d4304884e02e2095fcae2fa45?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/bdb96b9c9dcceab647b4c98e8321c21095f85a9d4304884e02e2095fcae2fa45?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                          className="grow w-full aspect-[0.83]"
                        />
                      </div>
                      <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                        <div className="flex flex-col grow self-stretch pl-8 text-neutral-400">
                          <div className="text-2xl font-semibold leading-9 text-neutral-900">
                            Кинематографическая фотопрогулка по Бруклинскому
                            мосту
                          </div>
                          <div className="mt-4 text-base leading-6">
                            Съемка проходит в парке Бруклинского моста и на
                            Бруклинском мосту, но я всегда готова снимать
                            клиентов в других местах по запросу и за
                            дополнительную плату.
                          </div>
                          <div className="flex gap-5 justify-between pr-16 mt-32 text-xs leading-4 whitespace-nowrap max-md:pr-5 max-md:mt-10">
                            <div className="flex gap-1 justify-between">
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/6117259d09ef5505f08f845e50b00ed9c881db59f92fb343295c84541fbe75dc?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                                className="justify-center items-center w-4 aspect-square"
                              />
                              <div className="grow">Сегодня</div>
                            </div>
                            <div className="flex gap-1 justify-between">
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/89750a6291a7b64365e9c8fb15eb157ef7bdfca645671104720cbedfe522d693?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                                className="justify-center items-center w-4 aspect-square"
                              />
                              <div className="grow">James Calzoni</div>
                            </div>
                            <div className="flex gap-1 justify-between">
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/438a07c6a40444b0d500ddc3ee43c4b6c4250660f3ef187d425dbfa032549df8?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                                className="justify-center items-center w-4 aspect-square"
                              />
                              <div>17</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/a7d867a65f5f293a19663fe7afcb56eb29a9220c84333c1474a542f774b52469?apiKey=49819ad3d25f4b5f837dad19f5421852&"
            className="self-stretch my-auto aspect-square w-[35px]"
          />
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/555004f42177d693311cc9a966c60e250c3b22ffaa73cade773b788e14757d4b?apiKey=49819ad3d25f4b5f837dad19f5421852&"
          className="self-center mt-16 aspect-[5] w-[70px] max-md:mt-10"
        />
      </div>
    </div>
  );
};
export default BlogList;
