import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rooms: null,
  person_count: 1,
  children_count: 0,
  check_in: null,
  check_out: null,
  custom_pack: null,
  meal_pack: null,
  transport: null,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // Reducer to update the number of rooms
    updateRooms: (state, action) => {
      state.rooms = action.payload; // Assuming payload contains the new value
    },

    // Reducer to update the person count
    updatePersonCount: (state, action) => {
      state.person_count = action.payload; // Assuming payload contains the new value
    },

    // Reducer to update the children count
    updateChildrenCount: (state, action) => {
      state.children_count = action.payload; // Assuming payload contains the new value
    },

    // Reducer to add a sightseen to the list
    addSightSeen: (state, action) => {
      state.custom_pack = action.payload; // Assuming payload contains the new sightseen
    },

    // Reducer to update the check-in date
    updateCheckIn: (state, action) => {
      state.check_in = action.payload; // Assuming payload contains the new date
    },

    // Reducer to update the check-out date
    updateCheckOut: (state, action) => {
      state.check_out = action.payload; // Assuming payload contains the new date
    },

    // Reducer to update the meals
    updateMeals: (state, action) => {
      state.meal_pack = action.payload; // Assuming payload contains the new value
    },

    updateTransport: (state, action) => {
      state.transport = action.payload;
    },

    addRoomsToCart: (state, action) => {
      const { rooms, person_count, children_count, check_in, check_out } =
        action.payload;
      state.rooms = rooms;
      state.person_count = person_count;
      state.children_count = children_count;
      state.check_in = check_in;
      state.check_out = check_out;
    },
    addMealToCart: (state, action) => {
      state.meal_pack = action.payload;
    },

    addSightSeenToCart: (state, action) => {
      state.custom_pack = action.payload;
    },
    addTransportToCart: (state, action) => {
      state.transport = action.payload;
    },
  },
});

export const { actions, reducer } = cartSlice;
