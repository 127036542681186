import useFetch from "../Components/useFetch";
import { Link } from "react-router-dom";
import truncateDescription from "../Components/truncateDescription";

const TourPacksOffers = () => {
  const {
    data: TourOffers,
    isLoading,
    error,
  } = useFetch(`${process.env.REACT_APP_API_URL}/api/v1/tourpacks/`);

  if (isLoading) {
    return (
      <div className="loading-preview">
        <div id="loader"></div>
        <h2>Loading...</h2>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={"tour-pack-main"}>
      {TourOffers.map((tour) => (
        <Link to={`tour/${tour.id}`} key={tour.id}>
          <div className={"tour-pack-view"} key={tour.id}>
            <div>
              <h2>{tour.pack_name}</h2>
              <p>Price: {tour.pack_price}</p>
              <p>Total Duration: {tour.pack_total_duration} Hours</p>
              <p>Tour City{tour.pack_destination}</p>
              <p>Description: {truncateDescription(tour.pack_description)}</p>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default TourPacksOffers;
