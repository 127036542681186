import React, { useState, Fragment, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import RoomRadio from "./RoomRadio";
import Counter from "./Counter";
import DatePick from "../Components/Header/DatePick";
import { Dialog, Transition } from "@headlessui/react";
import MealItem from "./MealItem";
import SightSeenSelect from "./SightSeenSelect";
import TransportSelect from "./TransportSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  addMealToCart,
  addRoomsToCart,
  addSightSeenToCart,
  addTransportToCart,
  createBookingRequest,
} from "../Actions/cartActions";
import {
  BOOKING_REQUEST_API_URL,
  MEAL_ADD_API_URL,
  ROOM_CREATE_API_URL,
  SIGHT_SEEN_API_URL,
  TRANSPORT_API_URL,
} from "./RequestURLs";
import LoginPop from "../Authorization/LoginPop";
import DialogModal from "../Components/DialogModal";

const componentsToRender = [
  RoomRadio,
  MealItem,
  SightSeenSelect,
  TransportSelect,
  // Add more components as needed
];
const dateRangeString = localStorage.getItem("SelectedDate");
const dateRange = JSON.parse(dateRangeString);

const RoomModal = ({ props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate
  const [currentComponentIndex, setCurrentComponentIndex] = useState(0);
  let [isOpen, setIsOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [showLoginPop, setShowLoginPop] = useState(false); // State to control LoginPop visibility
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const AddToCartFromStore = useSelector((state) => state.cartReducer);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Get authentication status
  
  
  
  
  function AddToCart() {
    const room_data = {
      rooms: AddToCartFromStore.rooms,
      person_count: AddToCartFromStore.person_count,
      children_count: AddToCartFromStore.children_count,
      check_in: AddToCartFromStore.check_in,
      check_out: AddToCartFromStore.check_out,
      actionType: "rooms",
    };
    const meal_data = {
      meal_pack: AddToCartFromStore.meal_pack,
      actionType: "meal",
    };
    const sight_seen_data = {
      custom_pack: AddToCartFromStore.custom_pack,
      actionType: "sightSeen",
    };
    const transport_data = {
      transportation: AddToCartFromStore.transport,
      actionType: "transport",
    };
    const booking_data = {
      hotel_rooms: AddToCartFromStore.rooms,
      person_count: AddToCartFromStore.person_count,
      children_count: AddToCartFromStore.children_count,
      check_in: AddToCartFromStore.check_in,
      check_out: AddToCartFromStore.check_out,
      meal_type: Array.isArray(AddToCartFromStore.meal_pack) 
                  ? AddToCartFromStore.meal_pack 
                  : [AddToCartFromStore.meal_pack], // Ensure this is an array
      tour_name: Array.isArray(AddToCartFromStore.custom_pack) 
                  ? AddToCartFromStore.custom_pack 
                  : [AddToCartFromStore.custom_pack], // Ensure this is an array
      transportation: AddToCartFromStore.transport,
      // dest_city: AddToCartFromStore.rooms.hotel.location_city,
  };
  
    
    dispatch(addRoomsToCart(room_data, ROOM_CREATE_API_URL));
    dispatch(addMealToCart(meal_data, MEAL_ADD_API_URL));
    dispatch(addSightSeenToCart(sight_seen_data, SIGHT_SEEN_API_URL));
    dispatch(addTransportToCart(transport_data, TRANSPORT_API_URL));
    dispatch(createBookingRequest(booking_data,BOOKING_REQUEST_API_URL));
  }

  function closeModal() {
    setIsOpen(false);
    setCurrentComponentIndex(0);
  }

  function openModal() {
    // if (!isAuthenticated) {
    //   setShowLoginPop(true); // Show the LoginPop modal
    //   return;
    // }

    setIsOpen(true);
    localStorage.removeItem("AdultCount");
    localStorage.removeItem("ChildCount");
    localStorage.removeItem("SelectedDate");
    localStorage.removeItem("SelectedMeal");
    localStorage.removeItem("selectedSightSeens");
    localStorage.removeItem("updatedTransport");
  }

  function nextData() {
    // Increment the index to render the next component
    setCurrentComponentIndex((prevIndex) => prevIndex + 1);
    // If all components are rendered, close the modal
    if (currentComponentIndex === componentsToRender.length - 1) {
      AddToCart();
      closeModal();
    }
  }

  const CurrentComponent = componentsToRender[currentComponentIndex];

  return (
    <>
      <button
        type="button"
        onClick={openModal}
        className="flex gap-3 justify-between px-14 tracking-normal text-white uppercase bg-orange-400 rounded-xl max-md:px-5"
      >
        Book now
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/d9af30ce1a36a775b4f5729bdd83ffc8c0a0ad06905d325ab867481971ba7e00?apiKey=49819ad3d25f4b5f837dad19f5421852&"
          className="my-auto w-6 aspect-square"
        />
      </button>

      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-lg">
                  <div className="bg-slate-100 px-4 pb-4 pt-5 sm:p-6 sm:pb-4 rounded-lg">
                    <div className=" items-start">
                      <div className="mt-3  text-left">
                        {isAuthenticated && (
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900"
                          >
                            Please Input Details
                          </Dialog.Title>
                        )}

                        {!isAuthenticated ? (
                          <LoginPop onClose={() => setIsOpen(true)} className={'max-w-full'} />
                        ) : (
                          <CurrentComponent props={props} />
                        )}
                      </div>
                    </div>
                  </div>
                  {isAuthenticated && (
                    <div className="bg-gray-50 px-4 py-3 items-center flex justify-center rounded-lg">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-[#0e7490] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#2e7b91] "
                        onClick={() => nextData()}
                      >
                        {currentComponentIndex === componentsToRender.length - 1
                          ? "FINISH"
                          : "PROCEED"}
                      </button>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default RoomModal;
