import React, { useState, useEffect } from "react";
import useFetch from "../Components/useFetch";

const CitySelect = ({ selectedCountry }) => {
  const [cities, setCities] = useState([]);
  const {
    data: allCities,
    isLoading,
    error,
  } = useFetch(`${process.env.REACT_APP_API_URL}/api/v1/cities/`);
  // 

  useEffect(() => {
    if (selectedCountry && allCities) {
      const filteredCities = allCities.filter(
        (city) => city.country.name === selectedCountry,
      );
      setCities(filteredCities);
    }
  }, [selectedCountry, allCities]);

  if (isLoading) {
    return (
      <div className="loading-preview">
        <div id="loader"></div>
        <h2>Loading...</h2>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <select style={{ width: "20%" }} name="cities" id="cities">
      {cities.map((city) => (
        <option value={city.name} key={city.id}>
          {city.name}
        </option>
      ))}
    </select>
  );
};

export default CitySelect;
