import React from "react";
import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import { composeWithDevTools } from "@redux-devtools/extension";
import { thunk } from "redux-thunk";
import rootReducer from "./Reducers"; // Update the path to your reducers file
import countrySlice from "./Reducers/countrySlice";
import { reducer as cartReducer } from "./Actions/cartSlice";

const initialState = {};

const middleware = [thunk];

const store = configureStore(
  {
    reducer: rootReducer, // Specify the root reducer
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(middleware),
    devTools: process.env.NODE_ENV !== "production",
  },
  {
    reducer: {
      country: countrySlice,
    },
  },
  {
    reducer: {
      cart: cartReducer,
    },
  }
);

export default store;
