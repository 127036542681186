import { combineReducers } from "redux";
import auth from "./auth";
import countrySlice from "./countrySlice";
import { reducer as cartReducer } from "../Actions/cartSlice";


export default combineReducers({
  auth,
  countrySlice,
  cartReducer,
});
