import { Link } from "react-router-dom";
import { logout } from "../Actions/auth";
import { connect } from "react-redux";
import React, { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  XMarkIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline";
import LogoImage from "../media/logo.svg";

const Navbar = ({ logout, isAuthenticated }) => {
  // const guestLinks = (
  //   <Fragment>
  //     <Link to="/login" className="block px-4 py-2 text-sm text-gray-700">Login or Sign-up</Link>
  //   </Fragment>
  // );

  // const authLinks = (
  //   <Fragment>
  //     <Link to="#!" onClick={logout} className="block px-4 py-2 text-sm text-gray-700">Logout</Link>
  //   </Fragment>
  // );

  const navigation = [
    { name: "Home", href: "/", current: true },
    { name: "Login", href: "/login", current: false },
    { name: "Destinations", href: "#", current: false },
    { name: "Contact us", href: "#", current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <Disclosure
      as="nav"
      className="bg-white border-gray-200 shadow-md shadow-slate-100/50"
    >
      <div className="flex flex-col justify-center">
        
        
        
        <div className="flex gap-0 self-center py-2 pr-7 pl-2 mt-6 max-w-full text-white whitespace-nowrap bg-cyan-700 rounded-md shadow-sm w-[1320px] max-md:flex-wrap max-md:pr-5">
          <div className="flex gap-4 justify-between py-4 pr-16 pl-4 rounded-[36px_0px_0px_36px] max-md:pr-5">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/2eb60926edeca8f904683b17be644dcfeac93e7c7f6eb99ee8a08db7bbb8e478?apiKey=49819ad3d25f4b5f837dad19f5421852&"
              className="my-auto w-6 aspect-square"
            />
            <div className="flex flex-col flex-1 justify-center">
              <div className="text-sm leading-5">Локация</div>
              <div className="text-base font-medium leading-6">
                Изучите направления
              </div>
            </div>
          </div>
          <div className="flex gap-4 justify-between py-4 pr-20 pl-4 max-md:pr-5">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/96eb7b97e7c2373039d50ab01921ba435318a2ea41d69c844228162a36b527bd?apiKey=49819ad3d25f4b5f837dad19f5421852&"
              className="my-auto w-6 aspect-square"
            />
            <div className="flex flex-col flex-1 justify-center">
              <div className="text-sm leading-5">Тур</div>
              <div className="text-base font-medium leading-6">
                Горячие туры
              </div>
            </div>
          </div>
          <div className="flex gap-4 justify-between py-4 pr-20 pl-4 max-md:pr-5">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/cff5dc224736acff3a262fa33e717395f1f71738c647bfad97d1311a3c7c2e88?apiKey=49819ad3d25f4b5f837dad19f5421852&"
              className="my-auto w-6 aspect-square"
            />
            <div className="flex flex-col flex-1 justify-center">
              <div className="text-sm leading-5">Когда?</div>
              <div className="text-base font-medium leading-6">
                Выберите дату
              </div>
            </div>
          </div>
          <div className="flex gap-4 justify-between py-4 pr-20 pl-4 max-md:pr-5">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/99510f98a631bbe9902ebe536fa78a40339cd9cd5993ffbbd7db8900d6573d2f?apiKey=49819ad3d25f4b5f837dad19f5421852&"
              className="my-auto w-6 aspect-square"
            />
            <div className="flex flex-col flex-1 justify-center">
              <div className="text-sm leading-5">Пассажиры</div>
              <div className="text-base font-medium leading-6">1 место</div>
            </div>
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/c0ca4ca595a0cde8f7822b5ce87f5c637ab7804fbca498bed74fd1d8238fb880?apiKey=49819ad3d25f4b5f837dad19f5421852&"
            className="my-auto aspect-square w-[33px]"
          />
        </div>
        {/* <div className="self-center mt-6 w-full shadow-sm max-w-[1320px] max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
            <div className="flex flex-col w-[67%] max-md:ml-0 max-md:w-full">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c40b7ecc690a68742b78383746b20d3cf417ae5759d1b5178561b720564ad158?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c40b7ecc690a68742b78383746b20d3cf417ae5759d1b5178561b720564ad158?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c40b7ecc690a68742b78383746b20d3cf417ae5759d1b5178561b720564ad158?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c40b7ecc690a68742b78383746b20d3cf417ae5759d1b5178561b720564ad158?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c40b7ecc690a68742b78383746b20d3cf417ae5759d1b5178561b720564ad158?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c40b7ecc690a68742b78383746b20d3cf417ae5759d1b5178561b720564ad158?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c40b7ecc690a68742b78383746b20d3cf417ae5759d1b5178561b720564ad158?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c40b7ecc690a68742b78383746b20d3cf417ae5759d1b5178561b720564ad158?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                className="grow w-full shadow-sm aspect-[1.67] max-md:mt-6 max-md:max-w-full"
              />
            </div>
            <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow max-md:mt-6 max-md:max-w-full">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a6aabc05af5471a756771d73912fcab3ac18fd6ba589ed018af3e9ad8e4b7c8d?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6aabc05af5471a756771d73912fcab3ac18fd6ba589ed018af3e9ad8e4b7c8d?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6aabc05af5471a756771d73912fcab3ac18fd6ba589ed018af3e9ad8e4b7c8d?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6aabc05af5471a756771d73912fcab3ac18fd6ba589ed018af3e9ad8e4b7c8d?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6aabc05af5471a756771d73912fcab3ac18fd6ba589ed018af3e9ad8e4b7c8d?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6aabc05af5471a756771d73912fcab3ac18fd6ba589ed018af3e9ad8e4b7c8d?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6aabc05af5471a756771d73912fcab3ac18fd6ba589ed018af3e9ad8e4b7c8d?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6aabc05af5471a756771d73912fcab3ac18fd6ba589ed018af3e9ad8e4b7c8d?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                  className="w-full shadow-sm aspect-[1.72] max-md:max-w-full"
                />
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/957863a423560ad81349b4f80e92eda9c6c3a63dff5f8d78ec3b31d1651805ae?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/957863a423560ad81349b4f80e92eda9c6c3a63dff5f8d78ec3b31d1651805ae?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/957863a423560ad81349b4f80e92eda9c6c3a63dff5f8d78ec3b31d1651805ae?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/957863a423560ad81349b4f80e92eda9c6c3a63dff5f8d78ec3b31d1651805ae?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/957863a423560ad81349b4f80e92eda9c6c3a63dff5f8d78ec3b31d1651805ae?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/957863a423560ad81349b4f80e92eda9c6c3a63dff5f8d78ec3b31d1651805ae?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/957863a423560ad81349b4f80e92eda9c6c3a63dff5f8d78ec3b31d1651805ae?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/957863a423560ad81349b4f80e92eda9c6c3a63dff5f8d78ec3b31d1651805ae?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                  className="mt-6 w-full shadow-sm aspect-[1.72] max-md:max-w-full"
                />
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="justify-center self-center p-4 mt-6 w-full bg-cyan-700 rounded-md border border-solid shadow-sm border-[color:var(--Gray-100,#E4E7E9)] max-w-[1320px] max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
            <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
              <div className="flex grow gap-4 justify-between p-4 text-sm text-white max-md:mt-10">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/7b3dd83b9a180f15ba0e4170fd056b9f8e170c83f774849998b2b4a2f7d4a5e7?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                  className="my-auto w-10 aspect-square"
                />
                <div className="flex flex-col flex-1">
                  <div className="font-medium uppercase whitespace-nowrap leading-[143%]">
                    Лучшие рейсы
                  </div>
                  <div className="mt-1 leading-5">
                    Самые низкие цены и качественное обслуживание
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
              <div className="flex grow gap-4 justify-between p-4 text-sm text-white max-md:mt-10">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/132e4b94872035461486409b996202594b65460dfa1f0cce842dc659466553ba?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                  className="my-auto w-10 aspect-square"
                />
                <div className="flex flex-col flex-1">
                  <div className="font-medium uppercase whitespace-nowrap leading-[143%]">
                    Расчетная погода
                  </div>
                  <div className="mt-1 leading-5">
                    Наши сервисы предостовляют самую точную погоду!
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
              <div className="flex grow gap-4 justify-between p-4 text-sm text-white max-md:mt-10">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/7328b9d2c4c54c953f060ade9ae944f8eb319c1c1bd4ec079e94e140bff1be10?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                  className="my-auto w-10 aspect-square"
                />
                <div className="flex flex-col flex-1">
                  <div className="font-medium uppercase whitespace-nowrap leading-[143%]">
                    Местные события
                  </div>
                  <div className="mt-1 leading-5">
                    Всегда оставайтесь в курсе всех увлекательных событий!
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
              <div className="flex grow gap-4 justify-between p-4 text-sm text-white max-md:mt-10">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f9f291d163e3deb06f71734b8a3ff2efa973806b2ebac2b0d88ecba8c24cd3bf?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                  className="my-auto w-10 aspect-square"
                />
                <div className="flex flex-col flex-1">
                  <div className="font-medium uppercase leading-[143%]">
                    Настройка
                  </div>
                  <div className="mt-1 leading-5">
                    Система фильтров и истории может гибко настраиваться
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center px-16 py-12 w-full max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col my-6 w-full max-w-[1362px] max-md:max-w-full">
            <div className="flex gap-5 justify-between px-px w-full font-semibold max-md:flex-wrap max-md:max-w-full">
              <div className="flex-auto text-2xl leading-8 text-zinc-900">
                Популярные направления
              </div>
              <div className="flex gap-2 justify-between py-1.5 text-sm leading-5 text-sky-400 whitespace-nowrap bg-white">
                <div className="grow">Browse All Product</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/7eb3f5f174abced25d2228ee5474be3ef833aa7325e339e9587b04f178e8f5c3?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                  className="w-5 aspect-square"
                />
              </div>
            </div>
            <div className="mt-16 max-md:mt-10 max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
                <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                  <div className="grow max-md:mt-8 max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
                      <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                        <div className="flex overflow-hidden relative flex-col grow justify-center rounded-xl aspect-[0.83] max-md:mt-8">
                          <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/fe7f5bf536f205c0c108534b90378a6327504f92a958f2ce14be2e03980dad48?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/fe7f5bf536f205c0c108534b90378a6327504f92a958f2ce14be2e03980dad48?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fe7f5bf536f205c0c108534b90378a6327504f92a958f2ce14be2e03980dad48?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/fe7f5bf536f205c0c108534b90378a6327504f92a958f2ce14be2e03980dad48?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/fe7f5bf536f205c0c108534b90378a6327504f92a958f2ce14be2e03980dad48?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fe7f5bf536f205c0c108534b90378a6327504f92a958f2ce14be2e03980dad48?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/fe7f5bf536f205c0c108534b90378a6327504f92a958f2ce14be2e03980dad48?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/fe7f5bf536f205c0c108534b90378a6327504f92a958f2ce14be2e03980dad48?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                            className="object-cover absolute inset-0 size-full"
                          />
                          <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/1066ce4890239fc3e4997e2634b4a74e468fb0fe0119d1dacac83f81d118490c?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1066ce4890239fc3e4997e2634b4a74e468fb0fe0119d1dacac83f81d118490c?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1066ce4890239fc3e4997e2634b4a74e468fb0fe0119d1dacac83f81d118490c?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1066ce4890239fc3e4997e2634b4a74e468fb0fe0119d1dacac83f81d118490c?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1066ce4890239fc3e4997e2634b4a74e468fb0fe0119d1dacac83f81d118490c?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1066ce4890239fc3e4997e2634b4a74e468fb0fe0119d1dacac83f81d118490c?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1066ce4890239fc3e4997e2634b4a74e468fb0fe0119d1dacac83f81d118490c?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1066ce4890239fc3e4997e2634b4a74e468fb0fe0119d1dacac83f81d118490c?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                            className="w-full aspect-[0.83]"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                        <div className="flex flex-col grow self-stretch pt-12 pb-1 max-md:mt-8">
                          <div className="mt-6 text-base leading-6 text-neutral-400">
                            Крупнейший город Турции на берегах пролива Босфор,
                            который разделяет его на европейскую и азиатскую
                            части. В Старом городе сохранились здания различных
                            исторических эпох. В районе Султанахмет расположены
                            возведенный римлянами Ипподром, где в течение многих
                            столетий
                          </div>
                          <div className="mt-4 text-base font-medium leading-6 text-neutral-900">
                            Grand Hotel Tremezzo
                          </div>
                          <div className="flex gap-1 justify-between mt-3">
                            <div className="flex gap-1 justify-between text-sm leading-5 text-neutral-400">
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/2b48f4bc9c45f407f31a3331ecd9a22b25e81712f261a88d677200f44e9c6964?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                                className="my-auto w-4 aspect-square"
                              />
                              <div className="flex-auto">Turkish, Stambul</div>
                            </div>
                            <div className="flex gap-0.5 my-auto">
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/185c4768260271fe19f5ff7ffb97ca60ada3cb854e92e577cbe997a7ab1015ae?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                                className="w-4 aspect-square"
                              />
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/185696c559c1b84179d129c9e2715c6634e39df1faf4029473600a184c68fe30?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                                className="w-4 aspect-square"
                              />
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/de81a1a1c63282cefff7de8a658cfe19877d406dcfaf42c9c646f0a4e8913455?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                                className="w-4 aspect-square"
                              />
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cbc71d7062b3812774f5c50fa0240a66b5ebb660998c295a5c61918a1ffcd734?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                                className="w-4 aspect-square"
                              />
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/63ae73bef0febfcdf2e0e501a4be6241bc1ecc99841aa694c63bd053a0fdd379?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                                className="w-4 aspect-square"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                  <div className="grow max-md:mt-8 max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
                      <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/18c1b87d1a59cafb5722c2eb47844355f39ecf65f6b37aa4422a9ff7429b11cc?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/18c1b87d1a59cafb5722c2eb47844355f39ecf65f6b37aa4422a9ff7429b11cc?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/18c1b87d1a59cafb5722c2eb47844355f39ecf65f6b37aa4422a9ff7429b11cc?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/18c1b87d1a59cafb5722c2eb47844355f39ecf65f6b37aa4422a9ff7429b11cc?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/18c1b87d1a59cafb5722c2eb47844355f39ecf65f6b37aa4422a9ff7429b11cc?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/18c1b87d1a59cafb5722c2eb47844355f39ecf65f6b37aa4422a9ff7429b11cc?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/18c1b87d1a59cafb5722c2eb47844355f39ecf65f6b37aa4422a9ff7429b11cc?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/18c1b87d1a59cafb5722c2eb47844355f39ecf65f6b37aa4422a9ff7429b11cc?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                          className="grow w-full aspect-[0.83]"
                        />
                      </div>
                      <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                        <div className="flex flex-col grow pt-2 pl-8">
                          <div className="text-2xl font-semibold leading-9 whitespace-nowrap text-neutral-900">
                            Англия, Лондон
                          </div>
                          <div className="mt-6 text-base leading-6 text-neutral-400">
                            Столица Англии и Соединенного Королевства. История
                            этого современного города уходит во времена римлян.
                            В самом центре Лондона находится здание парламента –
                            Вестминстерский дворец, а также знаменитая часовая
                            башня Биг-Бен и Вестминстерское аббатство
                          </div>
                          <div className="mt-16 text-base font-medium leading-6 text-neutral-900 max-md:mt-10">
                            The Oberoi Udaivilas, Udaipur
                          </div>
                          <div className="flex gap-1 justify-between mt-3">
                            <div className="flex gap-1 justify-between text-sm leading-5 text-neutral-400">
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/58449511126bd264d603a5ba6f46faf360bef82d9e629c3aaca54cf5821e7935?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                                className="self-start w-4 aspect-square"
                              />
                              <div className="flex-auto">Englang, London</div>
                            </div>
                            <div className="flex gap-0.5 self-start">
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/b129f461c846ed81f9adc0d8c9176e9bf6c0a0d38dee7a87b9e9fd2613d421f3?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                                className="w-4 aspect-square"
                              />
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/7b8c75bf71c28a9c3b99e9c2d94d805bbf9883d5be20880050dd3f306f22bd4a?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                                className="w-4 aspect-square"
                              />
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/4dc70e65a74921e26b676f5db2174f3fabad7d529bd205a7cdc7ab018fa223f3?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                                className="w-4 aspect-square"
                              />
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/30dc3d2def81334acb585695d4891b3cd101f6ebd0fa221398135f554cc33ea3?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                                className="w-4 aspect-square"
                              />
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/4e59f9a07fc994aa4a15402d38a47fd4fc44e9b2e1ede0a963799d4b47db6383?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                                className="w-4 aspect-square"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/ab3a08ae7d0cd6a8df2313e18b45119bbbfcfcbc24ba70219b4049652a53f392?apiKey=49819ad3d25f4b5f837dad19f5421852&"
              className="self-center mt-14 aspect-[5] w-[70px] max-md:mt-10"
            />
          </div>
        </div> */}
        {/* <div className="flex justify-center items-center px-16 py-12 w-full max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col my-6 w-full max-w-[1362px] max-md:max-w-full">
            <div className="flex gap-5 justify-between px-px w-full font-semibold max-md:flex-wrap max-md:max-w-full">
              <div className="flex-auto text-2xl leading-8 text-zinc-900">
                Поиск по типу размещения
              </div>
              <div className="flex gap-2 justify-between py-1.5 text-sm leading-5 text-sky-400 whitespace-nowrap bg-white">
                <div className="grow">Browse All Product</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/7eb3f5f174abced25d2228ee5474be3ef833aa7325e339e9587b04f178e8f5c3?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                  className="w-5 aspect-square"
                />
              </div>
            </div>
            <div className="flex gap-5 justify-between px-px mt-16 text-base font-medium leading-6 whitespace-nowrap text-neutral-900 max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
              <div className="flex flex-col flex-1">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                  className="self-center aspect-[1.11] w-[200px]"
                />
                <div className="mt-2">Вилла</div>
              </div>
              <div className="flex flex-col flex-1">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/bda648ae8a205545afffb42ff404fbe67331f0f387f1a7cf7994fac3bd5d2f89?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/bda648ae8a205545afffb42ff404fbe67331f0f387f1a7cf7994fac3bd5d2f89?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bda648ae8a205545afffb42ff404fbe67331f0f387f1a7cf7994fac3bd5d2f89?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/bda648ae8a205545afffb42ff404fbe67331f0f387f1a7cf7994fac3bd5d2f89?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/bda648ae8a205545afffb42ff404fbe67331f0f387f1a7cf7994fac3bd5d2f89?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bda648ae8a205545afffb42ff404fbe67331f0f387f1a7cf7994fac3bd5d2f89?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/bda648ae8a205545afffb42ff404fbe67331f0f387f1a7cf7994fac3bd5d2f89?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/bda648ae8a205545afffb42ff404fbe67331f0f387f1a7cf7994fac3bd5d2f89?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                  className="self-center aspect-[1.11] w-[200px]"
                />
                <div className="mt-2">Коттедж</div>
              </div>
              <div className="flex flex-col flex-1">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/4d1f775b06ed1c055ec712196ec257526b9f3dd02304c9ad72ac4a49f6f8730f?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/4d1f775b06ed1c055ec712196ec257526b9f3dd02304c9ad72ac4a49f6f8730f?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4d1f775b06ed1c055ec712196ec257526b9f3dd02304c9ad72ac4a49f6f8730f?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/4d1f775b06ed1c055ec712196ec257526b9f3dd02304c9ad72ac4a49f6f8730f?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/4d1f775b06ed1c055ec712196ec257526b9f3dd02304c9ad72ac4a49f6f8730f?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4d1f775b06ed1c055ec712196ec257526b9f3dd02304c9ad72ac4a49f6f8730f?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/4d1f775b06ed1c055ec712196ec257526b9f3dd02304c9ad72ac4a49f6f8730f?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/4d1f775b06ed1c055ec712196ec257526b9f3dd02304c9ad72ac4a49f6f8730f?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                  className="self-center aspect-[1.11] w-[200px]"
                />
                <div className="mt-2">Шале</div>
              </div>
              <div className="flex flex-col flex-1">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/893cfd4a352eebeb8a94a4c0979e848fbce84210bb84ea7d30fb168444790c46?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/893cfd4a352eebeb8a94a4c0979e848fbce84210bb84ea7d30fb168444790c46?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/893cfd4a352eebeb8a94a4c0979e848fbce84210bb84ea7d30fb168444790c46?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/893cfd4a352eebeb8a94a4c0979e848fbce84210bb84ea7d30fb168444790c46?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/893cfd4a352eebeb8a94a4c0979e848fbce84210bb84ea7d30fb168444790c46?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/893cfd4a352eebeb8a94a4c0979e848fbce84210bb84ea7d30fb168444790c46?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/893cfd4a352eebeb8a94a4c0979e848fbce84210bb84ea7d30fb168444790c46?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/893cfd4a352eebeb8a94a4c0979e848fbce84210bb84ea7d30fb168444790c46?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                  className="self-center aspect-[1.11] w-[200px]"
                />
                <div className="mt-2">Квартиры/Апартаменты</div>
              </div>
              <div className="flex flex-col flex-1">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/7ff77160a0a5c39b5224eb326cd4712d6c40f322e86181e256131b0be25767e1?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/7ff77160a0a5c39b5224eb326cd4712d6c40f322e86181e256131b0be25767e1?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7ff77160a0a5c39b5224eb326cd4712d6c40f322e86181e256131b0be25767e1?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/7ff77160a0a5c39b5224eb326cd4712d6c40f322e86181e256131b0be25767e1?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/7ff77160a0a5c39b5224eb326cd4712d6c40f322e86181e256131b0be25767e1?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7ff77160a0a5c39b5224eb326cd4712d6c40f322e86181e256131b0be25767e1?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/7ff77160a0a5c39b5224eb326cd4712d6c40f322e86181e256131b0be25767e1?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/7ff77160a0a5c39b5224eb326cd4712d6c40f322e86181e256131b0be25767e1?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                  className="self-center aspect-[1.11] w-[200px]"
                />
                <div className="mt-2">Отели</div>
              </div>
              <div className="flex flex-col flex-1">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/6b2c70b9afcd560ede48532a5146ce9cfe99c054ee47edd257fb6371ffa5ff14?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6b2c70b9afcd560ede48532a5146ce9cfe99c054ee47edd257fb6371ffa5ff14?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6b2c70b9afcd560ede48532a5146ce9cfe99c054ee47edd257fb6371ffa5ff14?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6b2c70b9afcd560ede48532a5146ce9cfe99c054ee47edd257fb6371ffa5ff14?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6b2c70b9afcd560ede48532a5146ce9cfe99c054ee47edd257fb6371ffa5ff14?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6b2c70b9afcd560ede48532a5146ce9cfe99c054ee47edd257fb6371ffa5ff14?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6b2c70b9afcd560ede48532a5146ce9cfe99c054ee47edd257fb6371ffa5ff14?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6b2c70b9afcd560ede48532a5146ce9cfe99c054ee47edd257fb6371ffa5ff14?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                  className="self-center aspect-[1.11] w-[200px]"
                />
                <div className="mt-2">Глэмпинг</div>
              </div>
            </div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/ab3a08ae7d0cd6a8df2313e18b45119bbbfcfcbc24ba70219b4049652a53f392?apiKey=49819ad3d25f4b5f837dad19f5421852&"
              className="self-center mt-8 aspect-[5] w-[70px]"
            />
          </div>
        </div> */}
        {/* <div className="flex flex-col items-center px-16 pb-12 w-full max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col mb-12 w-full max-w-[1362px] max-md:mb-10 max-md:max-w-full">
            <div className="flex gap-5 justify-between px-px w-full font-semibold whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
              <div className="flex-auto text-2xl leading-8 text-zinc-900">
                Активности
              </div>
              <div className="flex gap-2 justify-between py-1.5 text-sm leading-5 text-sky-400 bg-white">
                <div className="grow">Browse All Product</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/7eb3f5f174abced25d2228ee5474be3ef833aa7325e339e9587b04f178e8f5c3?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                  className="w-5 aspect-square"
                />
              </div>
            </div>
            <div className="px-px mt-16 max-md:mt-10 max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
                <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow text-base font-medium leading-6 text-neutral-900 max-md:mt-8">
                    <img
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/009e90c802fb3ffba604f5b4ab106e1de19550ea3a8178d76247a622b1235d61?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/009e90c802fb3ffba604f5b4ab106e1de19550ea3a8178d76247a622b1235d61?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/009e90c802fb3ffba604f5b4ab106e1de19550ea3a8178d76247a622b1235d61?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/009e90c802fb3ffba604f5b4ab106e1de19550ea3a8178d76247a622b1235d61?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/009e90c802fb3ffba604f5b4ab106e1de19550ea3a8178d76247a622b1235d61?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/009e90c802fb3ffba604f5b4ab106e1de19550ea3a8178d76247a622b1235d61?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/009e90c802fb3ffba604f5b4ab106e1de19550ea3a8178d76247a622b1235d61?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/009e90c802fb3ffba604f5b4ab106e1de19550ea3a8178d76247a622b1235d61?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                      className="w-full aspect-[0.83]"
                    />
                    <div className="mt-4">Парусный спорт</div>
                  </div>
                </div>
                <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow text-base font-medium leading-6 whitespace-nowrap text-neutral-900 max-md:mt-8">
                    <img
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b1bfe584dfd2114e7362655c408a36542959ac2d422c6a1d2d0ce555eddc7ffe?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b1bfe584dfd2114e7362655c408a36542959ac2d422c6a1d2d0ce555eddc7ffe?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b1bfe584dfd2114e7362655c408a36542959ac2d422c6a1d2d0ce555eddc7ffe?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b1bfe584dfd2114e7362655c408a36542959ac2d422c6a1d2d0ce555eddc7ffe?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b1bfe584dfd2114e7362655c408a36542959ac2d422c6a1d2d0ce555eddc7ffe?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b1bfe584dfd2114e7362655c408a36542959ac2d422c6a1d2d0ce555eddc7ffe?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b1bfe584dfd2114e7362655c408a36542959ac2d422c6a1d2d0ce555eddc7ffe?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b1bfe584dfd2114e7362655c408a36542959ac2d422c6a1d2d0ce555eddc7ffe?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                      className="w-full aspect-[0.83]"
                    />
                    <div className="mt-4">Скалолазание</div>
                  </div>
                </div>
                <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow text-base font-medium leading-6 rounded-xl text-neutral-900 max-md:mt-8">
                    <img
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/f5387efebc0ce1b84c537a59b44168cbab514167894d553fc195f37c04c7cde6?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/f5387efebc0ce1b84c537a59b44168cbab514167894d553fc195f37c04c7cde6?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f5387efebc0ce1b84c537a59b44168cbab514167894d553fc195f37c04c7cde6?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/f5387efebc0ce1b84c537a59b44168cbab514167894d553fc195f37c04c7cde6?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/f5387efebc0ce1b84c537a59b44168cbab514167894d553fc195f37c04c7cde6?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f5387efebc0ce1b84c537a59b44168cbab514167894d553fc195f37c04c7cde6?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/f5387efebc0ce1b84c537a59b44168cbab514167894d553fc195f37c04c7cde6?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/f5387efebc0ce1b84c537a59b44168cbab514167894d553fc195f37c04c7cde6?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                      className="w-full aspect-[0.83]"
                    />
                    <div className="mt-4">Катание на лыжах</div>
                  </div>
                </div>
                <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow text-base font-medium leading-6 text-neutral-900 max-md:mt-8">
                    <img
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/2110c36b794dc95141ccfebd900670c302ac23ce2ff32f7c41cb6156782ae1eb?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/2110c36b794dc95141ccfebd900670c302ac23ce2ff32f7c41cb6156782ae1eb?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2110c36b794dc95141ccfebd900670c302ac23ce2ff32f7c41cb6156782ae1eb?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/2110c36b794dc95141ccfebd900670c302ac23ce2ff32f7c41cb6156782ae1eb?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/2110c36b794dc95141ccfebd900670c302ac23ce2ff32f7c41cb6156782ae1eb?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2110c36b794dc95141ccfebd900670c302ac23ce2ff32f7c41cb6156782ae1eb?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/2110c36b794dc95141ccfebd900670c302ac23ce2ff32f7c41cb6156782ae1eb?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/2110c36b794dc95141ccfebd900670c302ac23ce2ff32f7c41cb6156782ae1eb?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                      className="w-full aspect-[0.83]"
                    />
                    <div className="mt-4">Пешие прогулки</div>
                  </div>
                </div>
              </div>
            </div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/ab3a08ae7d0cd6a8df2313e18b45119bbbfcfcbc24ba70219b4049652a53f392?apiKey=49819ad3d25f4b5f837dad19f5421852&"
              className="self-center mt-8 aspect-[5] w-[70px]"
            />
          </div>
        </div> */}
        {/* <div className="flex justify-center items-center px-16 py-12 w-full bg-cyan-700 max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col items-center my-6 w-full max-w-[1320px] max-md:max-w-full">
            <div className="self-stretch text-3xl font-semibold text-center text-white max-md:max-w-full">
              Следите за нами!
            </div>
            <div className="flex gap-4 p-3 mt-8 max-w-full whitespace-nowrap bg-white rounded-md shadow-lg w-[700px] max-md:flex-wrap">
              <div className="grow justify-center items-start py-5 pr-16 pl-4 text-base leading-6 bg-white rounded-sm text-slate-500 max-md:pr-5 max-md:max-w-full">
                Email address
              </div>
              <div className="flex gap-2 justify-between px-6 text-sm font-bold tracking-normal leading-10 text-white uppercase bg-orange-400 rounded-sm max-md:px-5">
                <div className="grow">пОЛУЧАТЬ РАССЫЛКУ</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/229f0f0e6aee1b2fa976a6842eda4e9b570ee38142380e004cac0094f4aae2f0?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                  className="my-auto w-5 aspect-square"
                />
              </div>
            </div>
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a624b9bd8e61a81d539c37f2a1e83eacf1b1fc672104683265b0f9fe7c27c930?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a624b9bd8e61a81d539c37f2a1e83eacf1b1fc672104683265b0f9fe7c27c930?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a624b9bd8e61a81d539c37f2a1e83eacf1b1fc672104683265b0f9fe7c27c930?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a624b9bd8e61a81d539c37f2a1e83eacf1b1fc672104683265b0f9fe7c27c930?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a624b9bd8e61a81d539c37f2a1e83eacf1b1fc672104683265b0f9fe7c27c930?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a624b9bd8e61a81d539c37f2a1e83eacf1b1fc672104683265b0f9fe7c27c930?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a624b9bd8e61a81d539c37f2a1e83eacf1b1fc672104683265b0f9fe7c27c930?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a624b9bd8e61a81d539c37f2a1e83eacf1b1fc672104683265b0f9fe7c27c930?apiKey=49819ad3d25f4b5f837dad19f5421852&"
              className="mt-8 max-w-full aspect-[6.67] w-[1073px]"
            />
          </div>
        </div> */}
        {/* <div className="flex justify-center items-center px-16 py-12 w-full text-white bg-zinc-900 max-md:px-5 max-md:max-w-full">
          <div className="flex gap-5 justify-between items-start my-6 max-md:flex-wrap max-md:max-w-full">
            <div className="flex flex-col flex-1 text-base leading-6">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/7a168522bda1d04bf1ea700cb9eff2d31a24f5914057f6f41e85f2b940d294a2?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                className="self-center aspect-[8.33] w-[315px]"
              />
              <div className="mt-6 text-sm leading-5 whitespace-nowrap text-slate-500">
                Справочный номер:
              </div>
              <div className="mt-1 text-lg font-medium leading-6 whitespace-nowrap">
                (629) 555-0129
              </div>
              <div className="mt-3 text-gray-400">Какой-то адрес.</div>
              <div className="mt-3 font-medium">info@kazroutes.com</div>
            </div>
            <div className="flex flex-col flex-1 self-stretch text-sm font-medium leading-5 text-gray-400">
              <div className="text-base leading-6 text-white uppercase">
                Страны
              </div>
              <div className="mt-5">США</div>
              <div className="mt-3">Бразилия</div>
              <div className="mt-3">Турция</div>
              <div className="flex gap-2 justify-between py-1.5 mt-1.5 text-white whitespace-nowrap">
                <div className="my-auto w-6 h-0.5 bg-yellow-400" />
                <div className="flex-auto">Европа</div>
              </div>
              <div className="mt-1.5">Азия</div>
              <div className="mt-3">Казахстан</div>
              <div className="flex gap-2 justify-between py-1.5 pr-1.5 mt-1.5 text-yellow-400 bg-zinc-900">
                <div className="flex-auto">Browse All Product</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/fbecd9a952be2fb7f9fe01f88c29b3abe0b5f91270892cd4f45b634195a764f6?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                  className="w-5 aspect-square"
                />
              </div>
            </div>
            <div className="flex flex-col flex-1 self-stretch pb-1.5 text-sm font-medium leading-5 text-gray-400">
              <div className="text-base leading-6 text-white uppercase whitespace-nowrap">
                быстрые ссылки
              </div>
              <div className="mt-5">Скидки</div>
              <div className="mt-3">Горячие путёвки</div>
              <div className="mt-3">Рекомендуем</div>
              <div className="mt-3">Страны</div>
              <div className="mt-3">Регионы</div>
              <div className="mt-3">Города</div>
              <div className="mt-3">Аппартаменты</div>
            </div>
            <div className="flex flex-col flex-1 text-white whitespace-nowrap">
              <div className="text-base font-medium leading-6 text-white uppercase">
                Скачать приложение
              </div>
              <div className="flex gap-4 justify-between px-6 py-4 mt-5 rounded bg-neutral-700 max-md:px-5">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/1c6ab38541faebec0f7e3878edbfb89b4e07515b1dd492f58dfa0fedcf9cc022?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                  className="my-auto w-8 aspect-square"
                />
                <div className="flex flex-col flex-1">
                  <div className="text-xs leading-3">Get it now</div>
                  <div className="mt-1 text-sm font-semibold leading-5">
                    Google Play
                  </div>
                </div>
              </div>
              <div className="flex gap-4 justify-between px-6 py-4 mt-3 rounded bg-neutral-700 max-md:px-5">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/ffc6eb613078b717d8e044e4b3db23db1173bdb988c6f64567c30e974923b0d4?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                  className="my-auto w-8 aspect-square"
                />
                <div className="flex flex-col flex-1">
                  <div className="text-xs leading-3">Get it now</div>
                  <div className="mt-1 text-sm font-semibold leading-5">
                    App Store
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col flex-1 text-sm font-medium leading-5">
              <div className="text-base leading-6 uppercase whitespace-nowrap">
                Популярные теги
              </div>
              <div className="flex gap-2 mt-5 whitespace-nowrap">
                <div className="justify-center px-3 py-1.5 rounded-sm border border-solid aspect-[2.28] border-[color:var(--Gray-800,#303639)]">
                  Турция
                </div>
                <div className="grow justify-center px-3 py-1.5 rounded-sm border border-solid border-[color:var(--Gray-800,#303639)]">
                  Казахстан
                </div>
                <div className="justify-center px-3 py-1.5 rounded-sm border border-solid aspect-[2.31] border-[color:var(--Gray-800,#303639)]">
                  Скидки
                </div>
                <div className="justify-center px-3 py-1.5 rounded-sm border border-solid aspect-[2.09] border-[color:var(--Gray-800,#303639)]">
                  Отели
                </div>
              </div>
              <div className="flex gap-2 pr-20 mt-2 max-md:pr-5">
                <div className="grow justify-center px-3 py-1.5 rounded-sm border border-solid border-[color:var(--Gray-800,#303639)]">
                  Стамбул{" "}
                </div>
                <div className="justify-center px-3 py-1.5 whitespace-nowrap rounded-sm border border-solid aspect-[1.78] border-[color:var(--Gray-800,#303639)]">
                  США
                </div>
                <div className="grow justify-center px-3 py-1.5 whitespace-nowrap rounded-sm border border-solid bg-neutral-700 border-[color:var(--Gray-00,#FFF)]">
                  Бразилия
                </div>
              </div>
              <div className="flex gap-2 pr-20 mt-2 whitespace-nowrap max-md:pr-5">
                <div className="grow justify-center px-3 py-1.5 rounded-sm border border-solid border-[color:var(--Gray-800,#303639)]">
                  Ресторан
                </div>
                <div className="grow justify-center px-3 py-1.5 rounded-sm border border-solid border-[color:var(--Gray-800,#303639)]">
                  Путёвки
                </div>
                <div className="justify-center px-3 py-1.5 rounded-sm border border-solid aspect-[2.19] border-[color:var(--Gray-800,#303639)]">
                  Линии
                </div>
              </div>
              <div className="flex gap-2 pr-16 mt-2 whitespace-nowrap max-md:pr-5">
                <div className="justify-center px-3 py-1.5 rounded-sm border border-solid aspect-[2.06] border-[color:var(--Gray-800,#303639)]">
                  Tablet
                </div>
                <div className="grow justify-center px-3 py-1.5 rounded-sm border border-solid border-[color:var(--Gray-800,#303639)]">
                  Microwave
                </div>
                <div className="grow justify-center px-3 py-1.5 rounded-sm border border-solid border-[color:var(--Gray-800,#303639)]">
                  Samsung
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="justify-center items-center px-16 py-6 w-full text-sm leading-5 text-center text-gray-400 shadow-sm bg-zinc-900 max-md:px-5 max-md:max-w-full">
          KAZROUTES - eCommerce Travel © 2024. Design by neatly studio
        </div>*/}
      </div> 
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="h-8 w-auto"
                    src={LogoImage}
                    alt="Your Company"
                  />
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "bg-violet-500 text-white"
                            : "text-gray-700 hover:bg-violet-500 hover:text-white",
                          "rounded-md px-3 py-2 text-sm font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <button
                  type="button"
                  className="relative rounded-full bg-violet-500 p-1 text-white hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <ShoppingCartIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Your Profile
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Settings
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Fragment>
                            {isAuthenticated ? (
                              <Link
                                to="#!"
                                onClick={logout}
                                className={classNames(
                                  "block px-4 py-2 text-sm",
                                  active
                                    ? "text-white bg-gray-700"
                                    : "text-gray-700 hover:bg-gray-100 hover:text-gray-700"
                                )}
                              >
                                Logout
                              </Link>
                            ) : (
                              <Link
                                to="/login"
                                className={classNames(
                                  "block px-4 py-2 text-sm",
                                  active
                                    ? "text-white bg-gray-700"
                                    : "text-gray-700 hover:bg-gray-100 hover:text-gray-700"
                                )}
                              >
                                Login or Sign-up
                              </Link>
                            )}
                          </Fragment>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(Navbar);
