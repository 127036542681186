import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import Counter from "./Counter";
import DatePick from "../Components/Header/DatePick";
import { Button } from "@mui/material";
import DialogModal from "../Components/DialogModal";
import { useDispatch } from "react-redux";
import { actions } from "../Actions/cartSlice";

// const plans = [
//   {
//     name: "Startup",
//     ram: "12GB",
//     cpus: "6 CPUs",
//     disk: "160 GB SSD disk",
//   },
//   {
//     name: "Business",
//     ram: "16GB",
//     cpus: "8 CPUs",
//     disk: "512 GB SSD disk",
//   },
//   {
//     name: "Enterprise",
//     ram: "32GB",
//     cpus: "12 CPUs",
//     disk: "1024 GB SSD disk",
//   },
// ];

// {
//     "rooms": null,
//     "person_count": null,
//     "children_count": null,
//     "check_in": null,
//     "check_out": null,
//     "total_days": null
// }

export default function RoomRadio({ props, onAddToCart }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedDate, setSelectedDate] = useState();
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [otherItemsModalOpen, setOtherItemsModalOpen] = useState(false);

  function AddToCart({ data }) {
    const token = localStorage.getItem("access");

    // Return the fetch promise explicitly
    return fetch(`${process.env.REACT_APP_API_URL}/api/v1/cart/cartitems/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...data, token }),
    })
      .then((response) => {
        if (response.ok) {
          setSuccessMessage("Item added to the cart successfully!");
        } else {
          setSuccessMessage(
            "Failed to add item to the cart. Please try again."
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error adding item to the cart:", error);
        setSuccessMessage(
          "An error occurred while adding the item. Please try again."
        );
        setIsLoading(false);
      });
  }

  function handleProceedClick() {
    if (!selected) {
      console.error("Please select a room.");
      return;
    }

    const selectedRoom = props.rooms.find((room) => room.id === selected);

    if (!selectedRoom) {
      console.error("Selected room not found.");
      return;
    }

    const data = {
      rooms: selectedRoom.id,
      person_count: adultCount,
      children_count: childCount,
      check_in: selectedDate.startDate,
      check_out: selectedDate.endDate,
    };

    setIsLoading(true);
    setSuccessMessage(null);
    setOtherItemsModalOpen(true);
    onAddToCart(data);

    // Assuming AddToCart returns a promise
    // AddToCart({ data })
    //   .then(() => {
    //     setOtherItemsModalOpen(true);
    //   })
    //   .catch((error) => {
    //     console.error("Error during AddToCart:", error);
    //     setSuccessMessage("An error occurred. Please try again.");
    //     setIsLoading(false);
    //   });
  }
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  function handleDateChange(value) {
    setSelectedDate(value);
    localStorage.setItem("SelectedDate", JSON.stringify(value));
    dispatch(actions.updateCheckIn(value.startDate));
    dispatch(actions.updateCheckOut(value.endDate));
  }

  function handleAdultCount(value) {
    setAdultCount(value);
    localStorage.setItem("AdultCount", JSON.stringify(value));
    dispatch(actions.updatePersonCount(value));
  }

  function handleChildCount(value) {
    setChildCount(value);
    localStorage.setItem("ChildCount", JSON.stringify(value));
    dispatch(actions.updateChildrenCount(value));
  }

  return (
    <div className="w-full px-4 py-16 pb-0">
      {successMessage && (
        <div className="text-green-500 mt-4">
          <DialogModal
            isOpen={isOpen}
            closeModal={closeModal}
            msg={successMessage}
          />
        </div>
      )}

      <div className="mx-auto w-full max-w-lg">
        <RadioGroup
          value={selected}
          onChange={(value) => {
            setSelected(value);
            localStorage.setItem("SelectedRoom", JSON.stringify(value));
            
            dispatch(actions.updateRooms(value));
          }}
        >
          <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
          <div className="space-y-2">
            {props.rooms.map((room, index) => (
              <RadioGroup.Option
                key={index}
                value={room.id}
                className={({ active, checked }) =>
                  `${
                    active
                      ? "ring-2 ring-white/60 ring-offset-2 ring-offset-sky-300"
                      : ""
                  }
                  ${checked ? "bg-sky-900/75 text-white" : "bg-white"}
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`font-medium  ${
                              checked ? "text-white" : "text-gray-900"
                            }`}
                          >
                            {room.room_type.name}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={`inline ${
                              checked ? "text-sky-100" : "text-gray-500"
                            }`}
                          >
                            <span>
                              {room.bed_type} / Max Allowed Guests{" "}
                              {room.max_guests}
                            </span>{" "}
                            <span aria-hidden="true">&middot;</span>{" "}
                            <span>
                              Starting Form: {room.b2c_price} $ per person per
                              night
                            </span>
                          </RadioGroup.Description>
                        </div>
                      </div>
                      {checked && (
                        <div className="shrink-0 text-white ml-4">
                          <CheckIcon className="h-6 w-6" />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
        <Counter
          onAdultChange={handleAdultCount}
          onChildChange={handleChildCount}
        />
        <div className="w-full  shadow-md rounded mb-16">
          <DatePick onDateChange={handleDateChange} />
        </div>
      </div>
    </div>
  );
}

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
