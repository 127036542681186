import { createSlice } from '@reduxjs/toolkit'


const defaultCountryState = {
    country: 1,
}

const CountrySlice = createSlice( {
    name: 'countrySlice',
    initialState: defaultCountryState,
    reducers: {
        setCountry(state, action) {
            state.country = action.payload;
        }
    },
})

export default CountrySlice.reducer
export const {
    setCountry,
 } = CountrySlice.actions
