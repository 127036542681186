import * as React from "react";
import useFetch from "../Components/useFetch";
import { useParams } from "react-router";
import ImageGallery from "react-image-gallery";
import ImageGalleryCustom from "./ImageGalleryCustom";
import StarRating from "../ProductList/StarRating";
import Counter from "./Counter";
import RoomModal from "./RoomModal";
import { Button } from "react-bootstrap";

function HotelDetail(props) {
  const allImageUrls = [];
  const { id } = useParams();
  const {
    data: hotel,
    isLoading,
    error,
  } = useFetch(`${process.env.REACT_APP_API_URL}/api/v1/hotels/` + id + "/");
  
  
  return (
    <div className="flex justify-center items-center px-16 py-12 w-full max-md:px-5 max-md:max-w-full">
      {error && <div>{error}</div>}
      {isLoading && (
        <div className="loading-preview">
          <div id="loader"></div>
          <h2>Loading...</h2>
        </div>
      )}
      {hotel && (
        <div className="flex flex-col items-start my-6 w-full max-w-[1321px] max-md:max-w-full">
          <div className="self-stretch max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0 text-left max-md: ">
              <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
                  {hotel.cover_image ? (
                    <img
                      src={hotel.cover_image}
                      alt={hotel.base_hotel.name}
                      className="w-full shadow-sm  max-md:max-w-full"
                    />
                  ) : (
                    <img
                      src={window.location.origin + "/img-not-found.png"}
                      alt={hotel.base_hotel.name}
                      className="w-full shadow-sm  max-md:max-w-full"
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
                <div className="flex gap-5 justify-between w-full max-md:flex-wrap max-md:max-w-full">
                  <div className="flex gap-0.5">
                    <StarRating
                      starCount={hotel.stars}
                      starColor="#FFA800"
                      className=" w-4 aspect-square"
                    />
                  </div>
                  {/* <div className="flex-auto text-sm leading-5 text-gray-400 text-right">
                    (21,671 Оценки пользователей)
                  </div> */}
                </div>
                <div className="mt-2 text-2xl font-bold leading-7 text-zinc-900 max-md:max-w-full">
                  {hotel.base_hotel.name}
                </div>
                <div className="mt-4 text-sm font-semibold leading-5 text-sky-400 max-md:max-w-full">
                  {hotel.location_city.country.name}, {hotel.location_city.name}
                </div>

                <div className="flex gap-3 justify-center self-start mt-6 whitespace-nowrap">
                  <div className="grow justify-center self-start px-2.5 py-1.5 text-sm font-semibold leading-5 text-white bg-orange-400 rounded-sm">
                    Самый выгодный вариант
                  </div>
                  <div className="flex gap-1 justify-between leading-[133%]">
                    <div className="grow text-2xl font-semibold text-sky-400">
                      $356
                    </div>
                    <div className="grow my-auto text-lg text-gray-400 line-through">
                      $1200
                    </div>
                  </div>
                </div>
                <div className="flex gap-4 pt-3 pr-3.5 mt-6 text-base font-bold whitespace-nowrap leading-[56px] max-md:flex-wrap max-md:max-w-full">
                  {/* <div className="flex gap-5 justify-between items-center px-5 py-4 text-center bg-white rounded-xl border-2 border-solid border-[color:var(---\_,#F2F4F5)] leading-[150%] text-neutral-700">
                    <Counter />
                  </div> */}
                  <div className="flex gap-3 justify-between ">
                    <RoomModal props={hotel} />
                  </div>
                  <div className="grow flex justify-center px-5 tracking-normal text-orange-400 uppercase rounded-xl border-2 border-solid border-[color:var(---,#FA8232)]">
                    <div className="self-center">ПОДРОБНЕЕ</div>
                  </div>
                </div>

                {/* <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full text-left">
                <div className="flex flex-col max-md:mt-10 max-md:max-w-full">
                  <div className="flex gap-5 justify-between w-full max-md:flex-wrap max-md:max-w-full">
                    <div className="flex gap-0.5">
                      <StarRating
                        starCount={hotel.stars}
                        starColor="#FFA800"
                        className=" w-4 aspect-square"
                      />
                    </div>
                    <div className="flex-auto text-sm leading-5 text-gray-400 text-right">
                      (21,671 Оценки пользователей)
                    </div>
                  </div>
                  <div className="mt-2 text-2xl font-bold leading-5 text-zinc-900 max-md:max-w-full">
                    {hotel.base_hotel.name}
                  </div>
                  <div className="mt-4 text-sm font-semibold leading-5 text-sky-400 max-md:max-w-full">
                    {hotel.location_city.country.name},{" "}
                    {hotel.location_city.name}
                  </div>
                  <div className="flex gap-3 justify-center self-start mt-6 whitespace-nowrap">
                    <div className="grow justify-center px-2.5 py-1.5 text-sm font-semibold leading-5 bg-orange-400 rounded-sm text-zinc-900">
                      Самый выгодный вариант
                    </div>
                    <div className="flex gap-1 my-auto text-gray-400">
                      <div className="grow text-lg leading-6 line-through	">
                        560 200
                      </div>
                      <div className="grow my-auto text-2xl font-semibold leading-5">
                        <span className="font-medium leading-5 text-zinc-900">
                          450 123
                        </span>{" "}
                        <span className="text-xs font-medium leading-5 text-gray-400">
                          тнг{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-5 justify-between py-3 mt-6 w-full text-base font-bold tracking-normal uppercase whitespace-nowrap leading-[56px] max-md:flex-wrap max-md:max-w-full">
                    <div className="flex gap-3 justify-between px-9 text-white bg-orange-400 rounded-xl max-md:px-5">
                      <div className="grow">забронировать место</div>
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/85e38aab30272b7e5aeaad1dd242ae48b4bde8a313bea6ccdcd29d701a3efcff?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                        className="my-auto w-6 aspect-square"
                      />
                    </div>
                    <div className="justify-center px-5 text-orange-400 rounded-xl border-2 border-solid border-[color:var(---,#FA8232)]">
                      ПОДРОБНЕЕ
                    </div>
                  </div>
                  <div className="shrink-0 mt-6 h-px bg-gray-100 max-md:max-w-full" />
                </div>
              </div> */}
              </div>
            </div>
          </div>
          <div className="self-stretch mt-20 max-md:mt-10 max-md:max-w-full text-left">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
              <div className="flex flex-col w-[68%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
                  <div className="mt-4 text-3xl font-semibold text-zinc-900 max-md:max-w-full">
                    Введение
                  </div>
                  <div className="mt-8 text-base leading-6 text-neutral-700 max-md:max-w-full">
                    {hotel.description}
                  </div>
                  <div className="flex gap-5 justify-between p-10 mt-8 text-xl leading-7 bg-rose-100 rounded-xl shadow-sm text-zinc-900 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/be2b6d1be08a944ab5b3308f1465a319eacbc20540f94970f4fe82b1f9a76e98?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                      className="self-start w-14 aspect-square"
                    />
                    <div className="flex-auto max-md:max-w-full">
                      {hotel.hotel_features.map((feature, index, array) =>
                        index === array.length - 1
                          ? feature.name + "."
                          : feature.name + ", "
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-[32%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col max-md:mt-10">
                  <div className="flex flex-col p-6 w-full bg-white rounded-xl border border-solid border-[color:var(---\_,#F2F4F5)] max-md:px-5">
                    <div className="text-base font-medium leading-6 uppercase text-zinc-900">
                      Галерея
                    </div>

                    <div className="flex gap-2.5 justify-between mt-6">
                      {hotel.rooms.map((room, index) => (
                        <React.Fragment key={index}>
                          {room.room_images &&
                            room.room_images.map((imageObj, imgIndex) => {
                              const imageUrl = imageObj.image;
                              allImageUrls.push(imageUrl); // Add the URL to the array
                            })}
                        </React.Fragment>
                      ))}
                      <ImageGalleryCustom images={allImageUrls} />
                    </div>
                  </div>

                  <div className="flex flex-col p-6 mt-6 text-base bg-white rounded-xl border border-solid border-[color:var(---\_,#F2F4F5)] max-md:px-5">
                    <div className="font-medium uppercase leading-[150%] text-zinc-900">
                      особенности
                    </div>
                    <div className="mt-6 leading-6 underline text-neutral-700">
                      <ul>
                        {hotel.hotel_features.map((feature, index, array) => (
                          <li key={index}>
                            <span className="font-bold underline">
                              {index === array.length - 1
                                ? `${feature.name}.`
                                : `${feature.name}, `}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="justify-center px-6 mt-6 text-sm font-bold tracking-normal leading-10 text-white uppercase whitespace-nowrap bg-orange-400 rounded-xl max-md:px-5">
            ВСЕ КОММЕНТАРИИ
          </div> */}
          <div className="mt-6 max-w-full h-px bg-gray-100 w-[846px]" />
        </div>
      )}
    </div>
  );
}

export default HotelDetail;
