// actions/cartActions.js
import { actions } from "./cartSlice";

export const addToCartRequest = (data, url) => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("access");

      // Store the actionType separately
      const actionType = data.actionType;

      const payload = {
        ...data,
        token,
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        // Dispatch the action based on the actionType
        switch (actionType) {
          case "rooms":
            dispatch(actions.addRoomsToCart(data));
            break;
          case "meal":
            dispatch(actions.addMealToCart(data));
            break;
          case "sightSeen":
            dispatch(actions.addSightSeenToCart(data));
            break;
          case "transport":
            dispatch(actions.addTransportToCart(data));
            break;
          case "booking":
            dispatch(actions.createBookingRequest(data));
            break;
          default:
            console.error("Invalid actionType specified in data.");
        }

        // You can dispatch additional actions or handle success here
      } else {
        // Handle error
        console.error("Failed to add item to the cart.");
      }
    } catch (error) {
      // Handle network error
      console.error("Error adding item to the cart:", error);
    }
  };
};

export const addRoomsToCart = (data, url) => {
  return addToCartRequest(data, url);
};

export const addMealToCart = (data, url) => {
  return addToCartRequest(data, url);
};

export const addSightSeenToCart = (data, url) => {
  return addToCartRequest(data, url);
};

export const addTransportToCart = (data, url) => {
  return addToCartRequest(data, url);
};

export const createBookingRequest = (data, url) => {
  return addToCartRequest(data, url);
};
