import whatsapp from "../media/whatsapp.svg";
import phone from "../media/phone-svgrepo-com.svg";
export default function SocialMediaBar() {
  return (
    <div className="flex justify-center items-center px-16 py-3 w-full bg-cyan-700 shadow-sm max-md:px-5 max-md:max-w-full">
      <div className="flex gap-5 justify-between w-full max-w-[1320px] max-md:flex-wrap max-md:max-w-full">
        <div className="flex-auto my-auto text-sm leading-5 text-white">
          Welcome to KazRoutes online travel store{" "}
        </div>
        <div className="flex gap-5 justify-between py-1">
          <div className="flex gap-3 justify-between">
            <div className="grow text-sm leading-5 text-white whitespace-nowrap">
              Discover us:
            </div>
            <div className="flex gap-3 my-auto ">
              <a href="https://wa.me/+919923387264" target="blank" className="pt-[2px]">
                <img
                  loading="lazy"
                  src={whatsapp}
                  className="w-4 aspect-square fill-white text-white "
                />
              </a>
              <a href="https://www.instagram.com/kazroutes/" target="blank" className="pt-[2px]">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/6a6ee6e0dfb8f8838ee82b55014502876c1e6a087e4ab403ae5248931c2dbd5d?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                  className="w-4 aspect-square"
                />
              </a>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/c30edf1cda62d256a1ec00d547b9df493bea0f109e3ca6913056aae1d205fc04?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                className="w-4 aspect-square"
              />
              <a href="tel:+919923387264" className="flex ">
                <img
                  loading="lazy"
                  src={phone}
                  className="w-4 aspect-square"
                />
                <p className="text-white text-sm">+91-992-338-7264</p>
              </a>

            </div>
          </div>
          {/* <div className="overflow-hidden relative flex-col justify-center pr-5 pl-px text-sm leading-5 text-white whitespace-nowrap aspect-[2.2]">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/8ff237ad5e0f796185f6b80f459a41adf835fb6b21821c176467d138659b3f34?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/8ff237ad5e0f796185f6b80f459a41adf835fb6b21821c176467d138659b3f34?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8ff237ad5e0f796185f6b80f459a41adf835fb6b21821c176467d138659b3f34?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/8ff237ad5e0f796185f6b80f459a41adf835fb6b21821c176467d138659b3f34?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/8ff237ad5e0f796185f6b80f459a41adf835fb6b21821c176467d138659b3f34?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8ff237ad5e0f796185f6b80f459a41adf835fb6b21821c176467d138659b3f34?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/8ff237ad5e0f796185f6b80f459a41adf835fb6b21821c176467d138659b3f34?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/8ff237ad5e0f796185f6b80f459a41adf835fb6b21821c176467d138659b3f34?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                className="object-cover absolute inset-0 size-full"
              />
              Rus
            </div>
            <div className="overflow-hidden relative flex-col justify-center pr-5 text-sm leading-5 text-white whitespace-nowrap aspect-[2.35]">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/10b7ef14f8a1412ae246ac430a64d908aa109082be55657dce6ad65d9136c1d8?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/10b7ef14f8a1412ae246ac430a64d908aa109082be55657dce6ad65d9136c1d8?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/10b7ef14f8a1412ae246ac430a64d908aa109082be55657dce6ad65d9136c1d8?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/10b7ef14f8a1412ae246ac430a64d908aa109082be55657dce6ad65d9136c1d8?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/10b7ef14f8a1412ae246ac430a64d908aa109082be55657dce6ad65d9136c1d8?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/10b7ef14f8a1412ae246ac430a64d908aa109082be55657dce6ad65d9136c1d8?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/10b7ef14f8a1412ae246ac430a64d908aa109082be55657dce6ad65d9136c1d8?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/10b7ef14f8a1412ae246ac430a64d908aa109082be55657dce6ad65d9136c1d8?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                className="object-cover absolute inset-0 size-full"
              />
              USD
            </div> */}
        </div>
      </div>
    </div>
  );
}
