import React from "react";

function AutherFooter() {
  return (
    <footer className="justify-center items-center px-16 py-6 w-full text-sm leading-5 text-center text-gray-400 shadow-sm bg-zinc-900 border-t border-gray-500 max-md:px-5 max-md:max-w-full">
      KAZROUTES - eCommerce Travel © 2024. Design by neatly studio
    </footer>
  );
}

export default AutherFooter;