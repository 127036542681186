import { Link } from "react-router-dom";
import { logout } from "../Actions/auth";
import { connect } from "react-redux";
import React, { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  XMarkIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline";
import LogoImage from "../media/logo.svg";
import LoginPop from "../Authorization/LoginPop";

const LogoProfileNavBar = ({ logout, isAuthenticated }) => {
  function checkAuth() {
    if (isAuthenticated) {
      return true;
    } else {
      return false;
    }
  }
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="flex justify-center items-center px-16 py-5 w-full bg-cyan-700 shadow-sm max-md:px-5 max-md:max-w-full">
      <div className="flex gap-5 justify-between w-full max-w-[1320px] max-md:flex-wrap max-md:max-w-full">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/f77d980e3f865963e4f64a98f3c362e2fb5855c5f8ec699866a308c3387a3083?apiKey=49819ad3d25f4b5f837dad19f5421852&"
          className="max-w-full aspect-[8.33] w-[314px]"
        />
        <div className="flex gap-5 justify-between items-center my-auto">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/9bf9b03b6ffaa50938bb3fa541cf6700ca5d52ee015945e15067c8f29c200f3e?apiKey=49819ad3d25f4b5f837dad19f5421852& "
            className="self-stretch my-auto w-6 aspect-square hidden"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/158dd040db8a3428f386c5625e099b2499e8a12680b1ed65a2f92b6ce84131d9?apiKey=49819ad3d25f4b5f837dad19f5421852&"
            className="self-stretch my-auto w-6 aspect-square hidden"
          />
          <Menu as="div" className="relative ">
            <div>
              <Menu.Button
                className="relative flex rounded-full bg-transparent text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                onClick={checkAuth()}
              >
                <span className="absolute -inset-1.5" />
                <span className="sr-only">Open user menu</span>

                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/c164ec423ab1f856de7637e419dfc92854bc04435614c3313f86825f67a6ef38?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                  className="self-stretch w-8 aspect-square bg-transparent"
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 2-full origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item>
                  {({ active, close }) => (
                    <Fragment>
                      {isAuthenticated ? (
                        <Link
                          to="#!"
                          onClick={() => {
                            logout();
                            close();
                          }}
                          className={classNames(
                            "block px-4 py-2 text-sm",
                            active
                              ? "text-white bg-gray-700"
                              : "text-gray-700 hover:bg-gray-100 hover:text-gray-700"
                          )}
                        >
                          Logout
                        </Link>
                      ) : (
                        <LoginPop onClose={close} />
                      )}
                    </Fragment>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(LogoProfileNavBar);
