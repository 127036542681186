import { useState, useEffect } from 'react';



const useFetch = (url) => {
    const [data, setData] = useState(
        // { title: "My new website", body: "lorem. ipsum", author: "The me!", id: 1 },
        // { title: "2nd Blog", body: "lorem. ipsum", author: "Mario", id: 2 },
        // { title: "My 3rd Blog", body: "lorem. ipsum", author: "The me!", id: 3 },
        // { title: "4th Blog", body: "lorem. ipsum", author: "Mario", id: 4 },
        null
    );
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    //This filters the shown data ->
    // const handleDelete = (id) => {
    //     const newdata = data.filter(blog => blog.id !== id);
    //     setData(newdata);
    // }

    

    // const handleClick = () =>{
    //     
    // }
    // const handleClickAgain = (name) =>{
    //     
    // }

    useEffect(() => {
        fetch(url)
            .then(res => {
                if (res.status === 404) {
                    throw Error('Page not found!')
                }
                if (!res.ok) {
                    throw Error('Something went wrong!')
                }
                else {
                    return res.json();
                }


            })
            .then(data => {
                setData(data);
                // 
                setIsLoading(false);
                setError(null);
            })
            .catch(err => {
                setError(err.message)
                setIsLoading(false);
            })
    }, [url]);
    return { data, isLoading, error }
}

export default useFetch;