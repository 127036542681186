import { Link, useLocation } from "react-router-dom";
import DatePick from "../Components/Header/DatePick";
import ChildrenCountOption from "./ChildrenCountOption";
import CountrySearchComboBox from "./CountrySearchComboBox";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setCountry } from "../Reducers/countrySlice";

const Devider = () => {
  return (
    <div className="divider flex flex-col justify-center px-0.5 my-auto aspect-[0.11]">
      <div className="shrink-0 bg-gray-400 h-[46px] w-[1px]" />
    </div>
  );
};

const TestSearchBar = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState({
    startDate: null, // Use new Date() directly
    endDate: null, // Set the end date to one year from now
  });
  const currPage = useLocation();
  

  const handleValueChange = (newValue) => {
    
    setValue(newValue);
  };
  function handleDispatch() {
    let selectedCountry = JSON.parse(
      localStorage.getItem("SelectedCountryForSearch")
    );
    dispatch(setCountry({ country: selectedCountry }));
  }
  return (
    <div className="flex flex-col justify-center">
      <form className="flex gap-0 self-center  pr-7 pl-2 mt-6 max-w-full bg-cyan-700 rounded-md shadow-sm w-[1320px] max-md:flex-wrap max-md:pr-5">
        <div className="header flex gap-8 justify-between py-4 pr-16 pl-4 text-white whitespace-nowrap rounded-[36px_0px_0px_36px] max-md:pr-5">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ed362d94131fee71faa51cec662798a5f4c313d38d7a0aa2d3237ec6d34da71c?apiKey=49819ad3d25f4b5f837dad19f5421852&"
            className="my-auto w-6 aspect-square"
            alt="Location"
          />
          <div className="flex flex-col flex-1 justify-center">
            <div className="text-sm leading-5">Локация</div>
            <CountrySearchComboBox />
          </div>
        </div>

        <Devider />
        <div className="flex gap-8 justify-between p-4 text-white whitespace-nowrap">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/7a9b822b820a06f25bbcea900b55c193270efbd6ad3de3b5cedf7d0d45d03d87?apiKey=49819ad3d25f4b5f837dad19f5421852&"
            className="my-auto w-6 aspect-square"
            alt="Tour"
          />
          <div className="flex flex-col flex-1 justify-center">
            <div className="text-sm leading-5">Тур</div>
            <div className="text-base font-medium leading-6">Горячие туры</div>
          </div>
        </div>

        <Devider />
        <div className="flex gap-8 justify-between py-4 pr-20 pl-4 text-white whitespace-nowrap max-md:pr-5">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ca588c9bb4254c9148c93a3eeab48c4727cd4e9d5aa409fc81468db96a4bccb?apiKey=49819ad3d25f4b5f837dad19f5421852&"
            className="my-auto w-6 aspect-square"
            alt="When"
          />
          <div className="flex flex-col flex-1 justify-center">
            <div className="text-sm leading-5">Когда?</div>
            <div className="text-base font-medium leading-6">
              <DatePick onDateChange={() => handleValueChange(value)} />
            </div>
          </div>
        </div>

        <Devider />
        <div className="flex gap-8 justify-between py-4 pl-4 text-white whitespace-nowrap max-md:pr-5 ">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/0d12429553b0128c2a139b50804b3eb4590da382e55a085b4ac270bf89ca82b0?apiKey=49819ad3d25f4b5f837dad19f5421852&"
            className="my-auto w-6 aspect-square"
            alt="Passengers"
          />
          <div className="flex flex-col flex-1 justify-center">
            <div className="text-sm leading-5">Пассажиры</div>
            <div className="text-base font-medium leading-6">
              <ChildrenCountOption />
            </div>
          </div>
        </div>
        <div className="flex text-white whitespace-nowrap max-md:pr-5 ml-auto gap-4">
          <Devider />
          <button onClick={handleDispatch()}>
            <Link to={"/search-results"}>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/7f6e0cab42089ef3b6c0254c47bb966138467cdaceb7b9e7b0c95dbfd20d4c05?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                className="my-auto aspect-square w-[33px]"
                alt="Booking"
              />
            </Link>
          </button>
        </div>
      </form>
      <div className="fixed top-16 w-72"></div>
    </div>
  );
};
export default TestSearchBar;
