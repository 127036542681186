import React, { useEffect } from "react";
import { connect } from "react-redux";
import { checkAuth, load_user } from "../Actions/auth";
import Navbar from "../Navbar/Navbar";
import OptionsBar from "../Navbar/OptionsBar";
import LogoProfileNavBar from "../Navbar/LogoProfileNavBar";
import SocialMediaBar from "../Navbar/SocialMediaBar";
import TestSearchBar from "../SearchBar/TestSearchBar";

const Layout = ({ checkAuth, load_user, children }) => {
  useEffect(() => {
    checkAuth();
    load_user();
  }, []);

  return (
    <div>
      <SocialMediaBar />
      <LogoProfileNavBar />
      {/* <OptionsBar /> */}
      <TestSearchBar />
      {/* <Navbar /> */}

      {children}
    </div>
  );
};

export default connect(null, { checkAuth, load_user })(Layout);
