import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { json } from "react-router-dom";
import CitySelect from "./CitySelect";
import SelectSearch from "../TestDev/SelectSearch";

const curr = new Date().toISOString().split("T")[0];
const currentDate = new Date(curr);

// Add 1 year to the current date
currentDate.setFullYear(currentDate.getFullYear() + 1);

// Convert the updated date back to a string in the 'YYYY-MM-DD' format
const maxDate = currentDate.toISOString().split("T")[0];

const Search = () => {
  const [input, setInput] = useState("");
  const fetchData = (value) => {
    fetch(`${process.env.REACT_APP_API_URL}/api/v1/countries/`)
      .then((response) => response.json())
      .then((json) => {
        const searchRes = json.filter((name) => {
          return (
            value &&
            name &&
            name.name &&
            name.name.toLowerCase().includes(value)
          );
        });
        setRes(searchRes);
      });
  };
  const [res, setRes] = useState([]);
  const handleChange = (value) => {
    setInput(value);
    // 
    fetchData(value);
  };
  return (
    <div className={"search-area-main"}>
      <div className={"search-area"}>
        <div className={"search-bar"}>
          <input
            type="text"
            name="country"
            // list="countryname"
            style={{ width: "20%" }}
            placeholder={"Type to search"}
            value={input}
            onChange={(e) => handleChange(e.target.value)}
          />
          <CitySelect selectedCountry={input} />

          <input
            style={{ width: "20%" }}
            type="date"
            id="start"
            name="trip-start"
            min={curr}
            max={maxDate}
          />
          <input style={{ width: "20%" }} type="number" />
          <button style={{ background: "purple" }}>
            <FontAwesomeIcon
              icon={faSearch}
              style={{ color: "white" }}
            ></FontAwesomeIcon>
          </button>
        </div>
        {/*<datalist id="countryname">*/}
        {/*  {res.map((country) => (*/}
        {/*    <option key={country.id} value={country.name}>*/}
        {/*      {country.name}*/}
        {/*    </option>*/}
        {/*  ))}*/}
        {/*</datalist>*/}
        {/*<SelectSearch />*/}
        <div className={"searchresareacontainer"}>
          <div className={"resultcontainer"}>
            {res.map((res, id) => {
              // {
              //   
              // }
              return (
                <div
                  className={"searchresult"}
                  key={res.id}
                  onClick={() => {
                    handleChange(res.name);
                  }}
                >
                  {res.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Search;
