import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { account_creation_confirmation_check } from "../Actions/auth";

const Activate = ({ account_creation_confirmation_check, isAuthenticated }) => {
  const { uid, token } = useParams();
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    if (await account_creation_confirmation_check(uid, token)) {
      navigate("/login");
    } else {
      
      navigate("/resend_activation_email"); //Create resend activation email.
    }
  };
  //Is user authed > redir to home
  return (
    <div className="flex-col overflow-hidden relative flex min-h-[1080px] justify-center items-center px-16 max-md:px-5">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/6294b8da39822a3ae856015455cdd647b2d84f4fed514972c32f3ae2e5da2364?apiKey=49819ad3d25f4b5f837dad19f5421852&"
        className="absolute object-cover object-center inset-0 size-full"
      />
      <div className="relative shadow-2xl backdrop-blur-[12.300000190734863px] bg-white bg-opacity-50 flex w-[992px] max-w-full flex-col text-base font-medium pl-20 pr-10 pt-8 pb-12 items-center max-md:px-5">
      <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/613f6a5fe171bfbf480de9b108b741478be6c1b8fa7181409a63e57f9e15ecf4?apiKey=49819ad3d25f4b5f837dad19f5421852&"
          className="aspect-square object-contain w-6"
          style={{alignSelf:"end"}}
        />
        <header className="text-slate-900 text-center text-3xl font-bold  mt-80 max-md:max-w-full max-md:mr-2.5 max-md:mt-10">
          Welcome!
        </header>
        <div
          className="text-slate-900  mt-11 max-md:max-w-full max-md:mr-2.5 max-md:mt-10"
          aria-label="Вы успешно подтвердили Вашу почту!"
        >
          <span>Activate</span>
        </div>
        <div
          className="text-slate-900  mt-10 max-md:max-w-full max-md:mr-2.5 max-md:mt-10"
          aria-label="Теперь Вы можете успешно авторизоваться!"
        >
          <span>Click here to activate your account!</span>
        </div>
        <form action="" onSubmit={(e) => onSubmit(e)}>
        <button
          className="text-zinc-50 text-xl font-medium bg-violet-500 w-[720px] max-w-full justify-center items-center mt-10 mb-64 px-16 py-7 rounded-[80px] max-md:mb-10 max-md:px-5"
          aria-label="Продолжить"
          type={"submit"}
        >
          Продолжить
        </button>
      </form>
        
      </div>
    </div>
  );
};

// const mapStateToProps = (state) => ({
//   isAuthenticated: state.auth.isAuthenticated,
// });

export default connect(null, { account_creation_confirmation_check })(Activate);
