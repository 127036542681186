import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { create_account } from "../Actions/auth";

const Signup = ({ create_account, isAuthenticated }) => {
  const [accountCreated, setAccountCreated] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    password: "",
    re_password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate(); // Hook for navigation

  const { email, name, password, re_password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  // useEffect(() => {
  //   // This useEffect will trigger when isAuthenticated changes
  //   if (isAuthenticated) {
  //     navigate("/"); // Navigate to the home page
  //   }
  // }, [isAuthenticated, navigate]);

  const onSubmit = async (e) => {
    e.preventDefault();
    
    if (password === re_password) {
      try {
        // Call create_account and handle the response
        await create_account(email, name, password, re_password);
  
        // If account creation is successful, navigate to the home page
        setAccountCreated(true);
        navigate("/registration-success");
      } catch (error) {
        // If there is an error, check if it is due to the email being already in use
        if (error.response && error.response.status === 409) {
          // Handle the case where the email is already in use
          
          // You can display an error message or take appropriate action
        } else {
          // Handle other errors
          console.error("Error creating account:", error.message);
        }
      }
    }
  };


  //Is user authed > redir to home
  return (
    <div className="flex-col overflow-hidden relative flex min-h-[1080px] justify-center items-center px-16 max-md:px-5">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/749c3971ddf7162feddd3cfd83547cd274c6ffccc7e755e0e25e25ce3e02a131?apiKey=49819ad3d25f4b5f837dad19f5421852&"
        className="absolute object-cover object-center inset-0 size-full"
      />
      <div className="relative shadow-2xl backdrop-blur-[12.300000190734863px] bg-white bg-opacity-50 flex w-[992px] max-w-full flex-col pl-20 pr-10 pt-8 pb-12 items-end max-md:px-5" style={{ alignItems: "center" }}>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/8d738a61cc29e228adc95623b8031a22025afd9a30f7c1b35cd6bc3120061eba?apiKey=49819ad3d25f4b5f837dad19f5421852&"
          className="aspect-square object-contain object-center w-6"
          style={{ alignSelf: "end" }}
        />
        <header className="text-slate-900 text-center text-3xl font-bold  mt-36 max-md:max-w-full max-md:mr-2.5 max-md:mt-10">
          Создайте свой аккаунт!
        </header>
        <div className="text-slate-900 text-base font-medium  mt-10 max-md:max-w-full max-md:mr-2.5">
          Зарегистрируйтесь используя Вашу почту!
        </div>
        <form id="registry-form" action="" onSubmit={(e) => onSubmit(e)}>
          <div className="text-white text-opacity-90 text-sm bg-gray-700 w-[485px] max-w-full justify-center mt-10 px-12 py-5 rounded-[80px] items-center max-md:px-5">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              type="email"
              name={"email"}
              value={email}
              placeholder={"Email"}
              className="form-control w-full outline-none bg-transparent"
              onChange={(e) => onChange(e)}
              required
            />
          </div>
          <div className="text-white text-opacity-90 text-sm bg-gray-700 w-[485px] max-w-full justify-center mt-10 px-12 py-5 rounded-[80px] items-center max-md:px-5">
            <label htmlFor="email" className="sr-only">
              Name
            </label>
            <input
              type="text"
              name={"name"}
              value={name}
              placeholder={"Name"}
              className="form-control w-full outline-none bg-transparent"
              onChange={(e) => onChange(e)}
              required
            // minLength={6}
            />
          </div>
          <div className="bg-gray-700 flex w-[485px] max-w-full items-stretch justify-between gap-5 mt-10 px-9 py-4 rounded-[80px] max-md:flex-wrap max-md:px-5">
            <div className="text-white text-opacity-90 text-sm grow shrink basis-auto">
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                type={showPassword ? "text" : "password"}
                name={"password"}
                value={password}
                placeholder={"Password"}
                className="form-control w-full outline-none bg-transparent"
                onChange={(e) => onChange(e)}
                required
              // minLength={6}
              />
            </div>
            <button onClick={handleShowPassword}>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/2e578726495071397eec256a9cdafcebacc5787369e9aff3e10256b793f26e66?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                className="aspect-[1.19] object-contain object-center w-[19px] fill-white fill-opacity-80 shrink-0"
                alt="arrow"
              />
            </button>
          </div>
          <div className="bg-gray-700 flex w-[485px] max-w-full items-stretch justify-between gap-5 mt-10 px-9 py-4 rounded-[80px] max-md:flex-wrap max-md:px-5">

            <div className="text-white text-opacity-90 text-sm grow shrink basis-auto">
              <label htmlFor="password" className="sr-only">
                Confirm Password
              </label>
              <input
                type={showPassword ? "text" : "password"}
                name={"re_password"}
                value={re_password}
                placeholder={"Confirm your password"}
                className="form-control w-full outline-none bg-transparent"
                onChange={(e) => onChange(e)}
                required
              // minLength={6}
              />
            </div>
            <button onClick={handleShowPassword}>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/2e578726495071397eec256a9cdafcebacc5787369e9aff3e10256b793f26e66?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                className="aspect-[1.19] object-contain object-center w-[19px] fill-white fill-opacity-80 shrink-0"
                alt="arrow"
              />
            </button>
          </div>
        </form>

        <div className="text-slate-900 text-right text-base font-bold self-center  mt-10">
          <Link to={"/reset-password"}> Забыли пароль?</Link>
        </div>
        <div className="text-slate-900 text-sm font-medium  mt-10 max-md:max-w-full max-md:mr-2.5">
          <span className="text-slate-900">Уже есть аккаунт?</span>{" "}
          <span className="font-bold text-slate-900"><Link to={"/login"}> Почему бы не войти?</Link></span>
        </div>
        <button className="text-zinc-50 text-xl font-medium bg-slate-900 w-[720px] max-w-full justify-center items-center mt-10 px-16 py-7 rounded-[80px] max-md:px-5" type={"submit"} form="registry-form">
          Продолжить
        </button>
        <div className="text-slate-900 text-center text-sm font-medium mt-10 mb-28 max-md:max-w-full max-md:mr-2.5 max-md:mb-10">
          Нажимая{" "}
          <span className="font-bold text-slate-900">“Продолжить”</span> - вы
          соглашаетесь с нашей <br />{" "}
          <span className="font-bold text-slate-900">
            “Политикой Конфиденциальности”{" "}
          </span>{" "}
        </div>
      </div>
    </div>
  );
};

// const mapStateToProps = (state) => ({
//   isAuthenticated: state.auth.isAuthenticated,
// });

export default connect(null, { create_account })(Signup);
