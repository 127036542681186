import * as React from "react";

function FilterColumn(props) {
  return (
    <div className="flex flex-col px-5 max-md:mt-10 text-left">
      <div className="text-base font-medium leading-6 uppercase whitespace-nowrap text-zinc-900">
        КЛАСС ОТЕЛЯ
      </div>

      <form>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/90d1d1a902e3890f3dfb103361460ea038418da9643eef5beda2d67993df26be?apiKey=49819ad3d25f4b5f837dad19f5421852&"
          className="mt-4 max-w-full aspect-[9.09] w-[140px]"
        />
        <div className="mt-6 text-base font-medium leading-6 uppercase whitespace-nowrap text-zinc-900">
          ТИП ОТЕЛЯ
        </div>
        <div className="flex gap-2 justify-between mt-4">
          <div className="flex flex-col justify-center p-1.5 aspect-square fill-orange-400">
            <div className="shrink-0 h-2 bg-white rounded-full" />
          </div>
          <div className="grow text-sm font-medium leading-5 text-zinc-900">
            Любой
          </div>
        </div>
        <div>
          <input
            type="checkbox"
            className="w-5 h-5 bg-white border border-solid border-[color:var(--Gray-200,#C9CFD2)] rounded-[100px] appearance-none checked:bg-blue-500"
          />
        </div>
        <div></div>
        <div className="flex gap-2 justify-between mt-3 text-sm leading-5 whitespace-nowrap text-neutral-600">
          <div className="w-5 h-5 bg-white border border-solid border-[color:var(--Gray-200,#C9CFD2)] rounded-[100px]" />
          <div className="grow">Отель</div>
        </div>
        <div className="flex gap-2 justify-between mt-3 text-sm leading-5 whitespace-nowrap text-neutral-600">
          <div className="w-5 h-5 bg-white border border-solid border-[color:var(--Gray-200,#C9CFD2)] rounded-[100px]" />
          <div className="grow">Экскурсионный тур</div>
        </div>
        <div className="flex gap-2 justify-between mt-3 text-sm leading-5 whitespace-nowrap text-neutral-600">
          <div className="w-5 h-5 bg-white border border-solid border-[color:var(--Gray-200,#C9CFD2)] rounded-[100px]" />
          <div className="grow">Гостевой дом</div>
        </div>
        <div className="flex gap-2 justify-between mt-3 text-sm leading-5 whitespace-nowrap text-neutral-600">
          <div className="w-5 h-5 bg-white border border-solid border-[color:var(--Gray-200,#C9CFD2)] rounded-[100px]" />
          <div className="grow">Апартменты</div>
        </div>
        <div className="flex gap-2 justify-between mt-3 text-sm leading-5 whitespace-nowrap text-neutral-600">
          <div className="w-5 h-5 bg-white border border-solid border-[color:var(--Gray-200,#C9CFD2)] rounded-[100px]" />
          <div className="grow">Вилла</div>
        </div>
        <div className="mt-6 text-base font-medium leading-6 uppercase text-zinc-900">
          питание
        </div>
        <div className="flex gap-2 justify-between mt-4">
          <div className="flex flex-col justify-center p-1.5 aspect-square fill-orange-400">
            <div className="shrink-0 h-2 bg-white rounded-full" />
          </div>
          <div className="grow text-sm font-medium leading-5 text-zinc-900">
            Любое
          </div>
        </div>
        <div className="flex gap-2 justify-between mt-3 text-sm leading-5 whitespace-nowrap text-neutral-600">
          <div className="w-5 h-5 bg-white border border-solid border-[color:var(--Gray-200,#C9CFD2)] rounded-[100px]" />
          <div className="grow">BB - Только завтрак</div>
        </div>
        <div className="flex gap-2 justify-between mt-3 text-sm leading-5 whitespace-nowrap text-neutral-600">
          <div className="w-5 h-5 bg-white border border-solid border-[color:var(--Gray-200,#C9CFD2)] rounded-[100px]" />
          <div className="grow">HB - Завтрак, ужин</div>
        </div>
        <div className="flex gap-2 justify-between mt-3 text-sm leading-5 whitespace-nowrap text-neutral-600">
          <div className="w-5 h-5 bg-white border border-solid border-[color:var(--Gray-200,#C9CFD2)] rounded-[100px]" />
          <div className="grow">FB - Полный пансион</div>
        </div>
        <div className="flex gap-2 justify-between mt-3 text-sm leading-5 whitespace-nowrap text-neutral-600">
          <div className="w-5 h-5 bg-white border border-solid border-[color:var(--Gray-200,#C9CFD2)] rounded-[100px]" />
          <div className="grow">Al - Всё включено</div>
        </div>
        <div className="flex gap-2 justify-between mt-3 text-sm leading-5 whitespace-nowrap text-neutral-600">
          <div className="w-5 h-5 bg-white border border-solid border-[color:var(--Gray-200,#C9CFD2)] rounded-[100px]" />
          <div className="grow">UAl - Ультра пакет</div>
        </div>
        <div className="shrink-0 mt-6 h-px bg-gray-200" />
        <div className="mt-6 text-base font-medium leading-6 uppercase text-zinc-900">
          бЮДЖЕТ
        </div>
        <div className="flex gap-5 justify-between px-16 mt-4 max-md:px-5">
          <div className="w-3.5 h-3 bg-white rounded-full stroke-[2px]" />
          <div className="w-3.5 h-3 bg-white rounded-full stroke-[2px]" />
        </div>
        <div className="flex gap-3 justify-between mt-4 text-sm leading-6 whitespace-nowrap text-slate-500">
          <div className="grow justify-center py-4 pr-9 pl-3 bg-white rounded border border-solid border-[color:var(--Gray-100,#E4E7E9)] max-md:pr-5">
            Минимальный
          </div>
          <div className="grow justify-center py-4 pr-9 pl-3 bg-white rounded border border-solid border-[color:var(--Gray-100,#E4E7E9)] max-md:pr-5">
            Максимальный
          </div>
        </div>
        <div className="flex gap-2 justify-between mt-4 text-sm leading-5 whitespace-nowrap text-neutral-600">
          <div className="w-5 h-5 bg-white border border-solid border-[color:var(--Gray-200,#C9CFD2)] rounded-[100px]" />
          <div className="grow">РУБ - Российские рубли</div>
        </div>
        <div className="flex gap-2 justify-between mt-3 text-sm leading-5 whitespace-nowrap text-neutral-600">
          <div className="w-5 h-5 bg-white border border-solid border-[color:var(--Gray-200,#C9CFD2)] rounded-[100px]" />
          <div className="grow">USD|EUR - Валюта</div>
        </div>
        <div className="flex gap-2 justify-between mt-3 text-sm leading-5 whitespace-nowrap text-neutral-600">
          <div className="w-5 h-5 bg-white border border-solid border-[color:var(--Gray-200,#C9CFD2)] rounded-[100px]" />
          <div className="grow">BYN - Белорусские рубли</div>
        </div>
        <div className="flex gap-2 justify-between mt-3 text-sm leading-5 whitespace-nowrap text-neutral-600">
          <div className="w-5 h-5 bg-white border border-solid border-[color:var(--Gray-200,#C9CFD2)] rounded-[100px]" />
          <div className="grow">UAH - Гривны</div>
        </div>
        <div className="flex gap-2 justify-between mt-3 text-sm leading-5 whitespace-nowrap text-neutral-600">
          <div className="w-5 h-5 bg-white border-2 border-solid border-[color:var(--Primary-500,#FA8232)] rounded-[100px]" />
          <div className="grow">TNG - Тенге</div>
        </div>
        <div className="shrink-0 mt-6 h-px bg-gray-200" />
        <div className="mt-6 text-base font-medium leading-6 uppercase text-zinc-900">
          Тур-операторы
        </div>
        <div className="flex gap-2 justify-between mt-4 text-sm leading-5 whitespace-nowrap text-neutral-600">
          <div className="flex gap-2 justify-between">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a520a2dab7209a0f333b8faaccf2b54f746c17156a2c44d9a2847a4a13bbacc?apiKey=49819ad3d25f4b5f837dad19f5421852&"
              className="w-5 aspect-square"
            />
            <div className="grow">ABK Tourism (KZ)</div>
          </div>
          <div className="flex gap-2 justify-between">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a520a2dab7209a0f333b8faaccf2b54f746c17156a2c44d9a2847a4a13bbacc?apiKey=49819ad3d25f4b5f837dad19f5421852&"
              className="w-5 aspect-square"
            />
            <div className="grow">Sanat</div>
          </div>
        </div>
        <div className="flex gap-2 justify-between mt-3 text-sm leading-5 whitespace-nowrap text-neutral-600">
          <div className="flex gap-2 justify-between">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a520a2dab7209a0f333b8faaccf2b54f746c17156a2c44d9a2847a4a13bbacc?apiKey=49819ad3d25f4b5f837dad19f5421852&"
              className="w-5 aspect-square"
            />
            <div className="grow">Anex</div>
          </div>
          <div className="flex gap-2 justify-between">
            <div className="w-5 h-5 bg-white rounded-sm border border-solid border-[color:var(--Gray-200,#C9CFD2)]" />
            <div className="grow">Selfie Travel</div>
          </div>
        </div>
        <div className="flex gap-2 justify-between mt-3 text-sm leading-5 whitespace-nowrap text-neutral-600">
          <div className="flex gap-2 justify-between">
            <div className="w-5 h-5 bg-white rounded-sm border border-solid border-[color:var(--Gray-200,#C9CFD2)]" />
            <div className="grow">Calypso</div>
          </div>
          <div className="flex gap-2 justify-between">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a520a2dab7209a0f333b8faaccf2b54f746c17156a2c44d9a2847a4a13bbacc?apiKey=49819ad3d25f4b5f837dad19f5421852&"
              className="w-5 aspect-square"
            />
            <div className="grow">Tez Tour</div>
          </div>
        </div>
        <div className="flex gap-2 justify-between mt-3 text-sm leading-5 whitespace-nowrap text-neutral-600">
          <div className="flex gap-2 justify-between">
            <div className="w-5 h-5 bg-white rounded-sm border border-solid border-[color:var(--Gray-200,#C9CFD2)]" />
            <div className="grow">IZI Tour</div>
          </div>
          <div className="flex gap-2 justify-between">
            <div className="w-5 h-5 bg-white rounded-sm border border-solid border-[color:var(--Gray-200,#C9CFD2)]" />
            <div className="grow">Арт Тревел</div>
          </div>
        </div>
        <div className="flex gap-2 justify-between mt-3 text-sm leading-5 whitespace-nowrap text-neutral-600">
          <div className="flex gap-2 justify-between">
            <div className="w-5 h-5 bg-white rounded-sm border-2 border-solid border-[color:var(--Primary-500,#FA8232)]" />
            <div className="grow">Join UP!</div>
          </div>
          <div className="flex gap-2 justify-between">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a520a2dab7209a0f333b8faaccf2b54f746c17156a2c44d9a2847a4a13bbacc?apiKey=49819ad3d25f4b5f837dad19f5421852&"
              className="w-5 aspect-square"
            />
            <div className="grow">FUN&SUN</div>
          </div>
        </div>
        <div className="flex gap-2 justify-between mt-3 text-sm leading-5 whitespace-nowrap text-neutral-600">
          <div className="flex gap-2 justify-between">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a520a2dab7209a0f333b8faaccf2b54f746c17156a2c44d9a2847a4a13bbacc?apiKey=49819ad3d25f4b5f837dad19f5421852&"
              className="w-5 aspect-square"
            />
            <div className="grow">Kazunion</div>
          </div>
          <div className="flex gap-2 justify-between">
            <div className="w-5 h-5 bg-white rounded-sm border border-solid border-[color:var(--Gray-200,#C9CFD2)]" />
            <div className="grow">BTC</div>
          </div>
        </div>
        <div className="flex gap-2 justify-between mt-3 text-sm leading-5 whitespace-nowrap text-neutral-600">
          <div className="w-5 h-5 bg-white rounded-sm border border-solid border-[color:var(--Gray-200,#C9CFD2)]" />
          <div className="flex-auto">Pegas</div>
        </div>
        <div className="mt-6 text-base font-medium leading-6 uppercase text-zinc-900">
          ОПЦИОНАЛЬНО
        </div>
        <div className="flex gap-2 justify-between mt-4 text-sm leading-5 whitespace-nowrap text-neutral-600">
          <div className="flex gap-2 justify-between">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a520a2dab7209a0f333b8faaccf2b54f746c17156a2c44d9a2847a4a13bbacc?apiKey=49819ad3d25f4b5f837dad19f5421852&"
              className="w-5 aspect-square"
            />
            <div className="grow">Только чартер</div>
          </div>
          <div className="flex gap-2 justify-between">
            <div className="w-5 h-5 bg-white rounded-sm border border-solid border-[color:var(--Gray-200,#C9CFD2)]" />
            <div className="grow">Гарантия мест</div>
          </div>
        </div>
        <div className="shrink-0 mt-6 h-px bg-gray-200" />
        <div className="mt-6 text-base font-medium leading-6 uppercase text-zinc-900">
          Теги
        </div>
        <div className="justify-center px-3 py-1.5 mt-5 text-sm font-medium leading-5 rounded-sm border border-solid border-[color:var(--Gray-100,#E4E7E9)] text-zinc-900">
          Отличный персонал
        </div>
        <div className="justify-center px-3 py-1.5 mt-2 text-sm font-medium leading-5 text-orange-400 bg-orange-50 rounded-sm border border-solid border-[color:var(--Primary-500,#FA8232)]">
          Близко к морю
        </div>
        <div className="justify-center px-3 py-1.5 mt-2 text-sm font-medium leading-5 whitespace-nowrap rounded-sm border border-solid border-[color:var(--Gray-100,#E4E7E9)] text-zinc-900">
          Отопление
        </div>
        <div className="flex gap-2 pr-3.5 mt-2 text-sm font-medium leading-5 whitespace-nowrap text-zinc-900">
          <div className="grow justify-center px-3 py-1.5 rounded-sm border border-solid border-[color:var(--Gray-100,#E4E7E9)]">
            Есть бассейн
          </div>
          <div className="grow justify-center px-3 py-1.5 rounded-sm border border-solid border-[color:var(--Gray-100,#E4E7E9)]">
            Рядом озеро
          </div>
          <div className="justify-center px-3 py-1.5 rounded-sm border border-solid aspect-[1.84] border-[color:var(--Gray-100,#E4E7E9)]">
            Wi-Fi
          </div>
        </div>
        <div className="flex gap-2 justify-between mt-2 text-sm font-medium leading-5 whitespace-nowrap text-zinc-900">
          <div className="grow justify-center px-3 py-1.5 rounded-sm border border-solid border-[color:var(--Gray-100,#E4E7E9)]">
            Стиральная машина
          </div>
          <div className="grow justify-center px-3 py-1.5 rounded-sm border border-solid border-[color:var(--Gray-100,#E4E7E9)]">
            Кондиционер
          </div>
        </div>
        <div className="flex flex-col justify-center p-8 mt-6 w-full text-sm font-bold tracking-normal leading-10 text-white uppercase whitespace-nowrap bg-white rounded border-4 border-solid border-[color:var(--Primary-100,#FFE7D6)] max-md:px-5">
          <div className="flex gap-2 justify-between px-16 bg-orange-400 rounded-sm max-md:px-5">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/75d88acee7289e2c40b5318beb68ee9b3a125f3e6ae4c24504829e4f15fd01c7?apiKey=49819ad3d25f4b5f837dad19f5421852&"
              className="my-auto w-5 aspect-square"
            />
            <div className="grow">Найти туры</div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default FilterColumn;
