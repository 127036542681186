import { Link } from "react-router-dom";
import StarRating from "./StarRating";

const MAX_DESCRIPTION_LENGTH = 200;
const BlogList = (/*props or*/ { blogs: hotels, title }) => {
  const truncateDescription = (description) => {
    if (description.length > MAX_DESCRIPTION_LENGTH) {
      return `${description.substring(0, MAX_DESCRIPTION_LENGTH)}...`;
    }
    return description;
  };
  // const blogs = props.blogs;

  return (
    <div className="blog-list">
      {hotels.map((hotel) => (
        <Link to={`blogs/${hotel.id}`} key={hotel.id}>
          <div className="blog-preview">
            <div key={hotel.id}>
              {/*<img>{hotel.room_images.image}</img>*/}
              {hotel.cover_image ? (
                <img src={hotel.cover_image} alt={hotel.base_hotel.name} />
              ) : (
                <img
                  src={window.location.origin + "/img-not-found.png"}
                  alt={hotel.base_hotel.name}
                />
              )}
              {/*{hotel.rooms[0] && hotel.rooms[0].room_images ? (*/}
              {/*  <img*/}
              {/*    src={hotel.rooms[0].room_images[0]?.image[0]?.image}*/}
              {/*    alt={hotel.base_hotel.name}*/}
              {/*  />*/}
              {/*) : (*/}
              {/*  <img*/}
              {/*    src={window.location.origin + "/img-not-found.png"}*/}
              {/*    alt={hotel.base_hotel.name}*/}
              {/*  />*/}
              {/*)}*/}
              <h2>{hotel.base_hotel.name}</h2>
              {/*<p>Stars: {hotel.stars}</p>*/}

              <StarRating starCount={hotel.stars} starColor="#ffffff" />
              <p>
                Location: {hotel.location_city.name},{" "}
                {hotel.location_country.name}
              </p>
              {/*<p>Total Rooms: {hotel.total_rooms}</p>*/}
              <p>Description: {truncateDescription(hotel.description)}</p>

              {/*<h3>Hotel Features:</h3>*/}
              {/*<ul>*/}
              {/*    {hotel.hotel_features.map((feature, index) => (*/}
              {/*        <li key={index}>{feature.name}</li>*/}
              {/*    ))}*/}
              {/*</ul>*/}

              {/*<h3>Rooms:</h3>*/}
              {/*{hotel.rooms.map((room, index) => (*/}
              {/*    <div key={index}>*/}
              {/*        <h4>{room.room_type.name}</h4>*/}
              {/*        <p>Max Guests: {room.max_guests}</p>*/}
              {/*        <p>Bed Type: {room.bed_type}</p>*/}

              {/*        <h5>Room Features:</h5>*/}
              {/*        <ul>*/}
              {/*            {room.room_features.map((feature, index) => (*/}
              {/*                <li key={index}>{feature}</li>*/}
              {/*            ))}*/}
              {/*        </ul>*/}

              {/*        <p>Description: {room.description}</p>*/}
              {/*    </div>*/}
              {/*))}*/}
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
  // return (<div className="blog-list">
  //
  //     {hotels.map((hotel) => (
  //         <div key={hotel.id}>
  //             <Link to={`blogs/${hotels.id}`} key={hotels.id}
  //             <h2>{hotel.base_hotel.name}</h2>
  //             <p>Location: {hotel.location_city.name}, {hotel.location_country.name}</p>
  //             <p>Total Rooms: {hotel.total_rooms}</p>
  //             <p>Description: {hotel.description}</p>
  //             <p>Stars: {hotel.stars}</p>
  //
  //             <h3>Hotel Features:</h3>
  //             <ul>
  //                 {hotel.hotel_features.map((feature, index) => (<li key={index}>{feature.name}</li>))}
  //             </ul>
  //
  //             <h3>Rooms:</h3>
  //                 {hotel.rooms.map((room, index) => (<div key={index}>
  //                     <h4>{room.room_type.name}</h4>
  //                     <p>Max Guests: {room.max_guests}</p>
  //                     <p>Bed Type: {room.bed_type}</p>
  //
  //                     <h5>Room Features:</h5>
  //                     <ul>
  //                         {room.room_features.map((feature, index) => (<li key={index}>{feature}</li>))}
  //                     </ul>
  //
  //                     <p>Description: {room.description}</p>
  //                 </div>))}
  //         </div>) < /Link>)}
  //         </div>);
  // return (
  //
  //     <div className="blog-list">
  //         <h1>{title}</h1>
  //         {blogs.map((blog) => {
  //             return (<Link to={`blogs/${blog.id}`} key={blog.id}>
  //                 <div className="blog-preview">
  //                     <h2>{blog.base_hotel.name}</h2>
  //                     <p>{blog.description}</p>
  //                     {blog.hotel_features.map((feature, index) => (
  //                         <li style={{listStyleType: 'none', padding: 0}} key={index}>{feature.name}</li>))}
  //                     <p>{blog.location_city.country.name}</p>
  //
  //                     {/* <button onClick={() => Delete(blog.id)}>delete blog</button> */}
  //                 </div>
  //             </Link>);
  //         })}
  //     </div>);
};

export default BlogList;
