import { redirect } from "react-router-dom";

const Delete = (id) => {
    fetch('http://localhost:8000/blogs/' + id, {
        method: "DELETE"
    }).then(() => {
        
    })
    return redirect("/");
}

export default Delete;
