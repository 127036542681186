import * as React from "react";
import { Link } from "react-router-dom";

function VerificationComponent() {
  return (
    <form className="flex-col overflow-hidden relative flex min-h-[1080px] justify-center items-center px-16 max-md:px-5">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/301cf08a08966dfe2c942d733a074f56d1299ed8d3c0201e17c775d7508b9a73?apiKey=49819ad3d25f4b5f837dad19f5421852&"
        className="absolute object-cover object-center inset-0 size-full"
      />
      <header className="relative shadow-2xl backdrop-blur-[12.300000190734863px] bg-white bg-opacity-50 flex w-[992px] max-w-full flex-col pl-20 pr-10 pt-8 pb-12 items-center max-md:px-5">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/613f6a5fe171bfbf480de9b108b741478be6c1b8fa7181409a63e57f9e15ecf4?apiKey=49819ad3d25f4b5f837dad19f5421852&"
          className="aspect-square object-contain w-6"
          style={{alignSelf:"end"}}
        />
        <h1
          className="text-slate-900 text-center text-3xl font-bold  mt-72 max-md:max-w-full max-md:mr-2.5 max-md:mt-10"
          aria-label="Проверьте свою почту!"
        >
          Проверьте свою почту!
        </h1>
        <p
          className="text-slate-900 text-base font-medium  mt-11 max-md:max-w-full max-md:mr-2.5 max-md:mt-10"
          aria-label="Мы отправили Вам письмо, пройдите верификацию"
        >
          Мы отправили Вам письмо, пройдите верификацию
        </p>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/54680d7f4674700011056a085a4bd6abdad755adaf3e148785dfa64ff86ebc77?apiKey=49819ad3d25f4b5f837dad19f5421852&"
          className="aspect-[1.01] object-contain object-center w-[123px] self-center max-w-full mt-10 max-md:mt-10"
        />
        <button
          className="text-zinc-50 text-xl font-medium bg-violet-500 w-[720px] max-w-full justify-center items-center mt-10 mb-64 px-16 py-7 rounded-[80px] max-md:mb-10 max-md:px-5"
          aria-label="Продолжить"
        >
          <Link to={"/login"}>Продолжить</Link>
        </button>
      </header>
    </form>
  );
}

export default VerificationComponent