import { useState } from "react";

const PeopleCounter = () => {
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);

  const handleIncrement = (inputName) => {
    switch (inputName) {
      case "adults":
        setAdults((prevAdults) => prevAdults + 1);
        break;
      case "children":
        setChildren((prevChildren) => prevChildren + 1);
        break;
      default:
        break;
    }
  };

  const handleDecrement = (inputName) => {
    switch (inputName) {
      case "adults":
        setAdults((prevAdults) => Math.max(prevAdults - 1, 1));
        break;
      case "children":
        setChildren((prevChildren) => Math.max(prevChildren - 1, 0));
        break;
      default:
        break;
    }
  };

  return (
    <div className="flex flex-col flex-1 justify-center">
      <div className="text-sm leading-5 text-neutral-400">Guests</div>
      <div className="text-base font-medium leading-6 text-neutral-900">
        <form className="max-w-xs mx-auto">
          <label for="adults-input" className="sr-only">
            Choose adults number:
          </label>
          <div className="relative flex items-center mb-2">
            <button
              type="button"
              id="decrement-button"
              onClick={() => handleDecrement("adults")}
              data-input-counter-decrement="adults-input"
              className="bg-gray-100 hover:bg-gray-400 border border-gray-300 
                  rounded-s-lg p-3 h-11 focus:ring-gray-100  focus:ring-2 focus:outline-none"
            >
              <svg
                className="w-3 h-3 text-gray-900 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 2"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h16"
                />
              </svg>
            </button>
            <input
              type="text"
              id="adults-input"
              data-input-counter
              data-input-counter-min="1"
              className="bg-transparent border-y-2 border-gray-200 h-11 font-medium text-center text-gray-900 text-sm 
                   block w-full pb-6 outline-none"
              placeholder=""
              value={adults}
              onChange={(e) => {
                const inputValue = e.target.value.trim(); // Trim whitespace
                if (inputValue === "") {
                  // Handle case where the input is empty
                  setAdults(null); // or set it to another default value if needed
                } else {
                  const parsedValue = parseInt(inputValue, 10);
                  if (!isNaN(parsedValue)) {
                    setAdults(parsedValue);
                  } else {
                    // Handle the case where the input is not a valid integer
                    console.error("Invalid integer input");
                  }
                }
              }}
              required
            />
            <div className="absolute bottom-1 start-1/2 -translate-x-1/2 rtl:translate-x-1/2 flex items-center text-xs text-gray-400 space-x-1 rtl:space-x-reverse">
              <svg
                className="w-2.5 h-2.5 text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 8v10a1 1 0 0 0 1 1h4v-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5h4a1 1 0 0 0 1-1V8M1 10l9-9 9 9"
                />
              </svg>
              <span>Adults</span>
            </div>
            <button
              type="button"
              id="increment-button"
              data-input-counter-increment="adults-input"
              onClick={() => handleIncrement("adults")}
              className="bg-gray-100 hover:bg-gray-400 border border-gray-300
                  rounded-e-lg p-3 h-11 focus:ring-gray-100  focus:ring-2 focus:outline-none"
            >
              <svg
                className="w-3 h-3 text-gray-900 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 1v16M1 9h16"
                />
              </svg>
            </button>
          </div>
          {/*  */}
          {/*  */}
          <label for="children-input" className="sr-only">
            Choose number of children:
          </label>
          <div className="relative flex items-center mb-2">
            <button
              type="button"
              id="decrement-button"
              data-input-counter-decrement="children-input"
              onClick={() => handleDecrement("children")}
              className="bg-gray-100 hover:bg-gray-400 border border-gray-300 
                  rounded-s-lg p-3 h-11 focus:ring-gray-100  focus:ring-2 focus:outline-none"
            >
              <svg
                className="w-3 h-3 text-gray-900"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 2"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h16"
                />
              </svg>
            </button>
            <input
              type="text"
              id="children-input"
              data-input-counter
              data-input-counter-min="0"
              className="bg-transparent border-y-2 border-gray-200 h-11 font-medium text-center text-gray-900 text-sm 
                   block w-full pb-6 outline-none"
              placeholder=""
              value={children}
              onChange={(e) => {
                const inputValue = e.target.value.trim(); // Trim whitespace
                if (inputValue === "") {
                  // Handle case where the input is empty
                  setChildren(null); // or set it to another default value if needed
                } else {
                  const parsedValue = parseInt(inputValue, 10);
                  if (!isNaN(parsedValue)) {
                    setChildren(parsedValue);
                  } else {
                    // Handle the case where the input is not a valid integer
                    console.error("Invalid integer input");
                  }
                }
              }}
              required
            />
            <div className="absolute bottom-1 start-1/2 -translate-x-1/2 rtl:translate-x-1/2 flex items-center text-xs text-gray-400 space-x-1 rtl:space-x-reverse">
              <svg
                className="w-2.5 h-2.5 text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="M6 1a1 1 0 0 0-2 0h2ZM4 4a1 1 0 0 0 2 0H4Zm7-3a1 1 0 1 0-2 0h2ZM9 4a1 1 0 1 0 2 0H9Zm7-3a1 1 0 1 0-2 0h2Zm-2 3a1 1 0 1 0 2 0h-2ZM1 6a1 1 0 0 0 0 2V6Zm18 2a1 1 0 1 0 0-2v2ZM5 11v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 11v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 15v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 15v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 11v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM5 15v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM2 4h16V2H2v2Zm16 0h2a2 2 0 0 0-2-2v2Zm0 0v14h2V4h-2Zm0 14v2a2 2 0 0 0 2-2h-2Zm0 0H2v2h16v-2ZM2 18H0a2 2 0 0 0 2 2v-2Zm0 0V4H0v14h2ZM2 4V2a2 2 0 0 0-2 2h2Zm2-3v3h2V1H4Zm5 0v3h2V1H9Zm5 0v3h2V1h-2ZM1 8h18V6H1v2Zm3 3v.01h2V11H4Zm1 1.01h.01v-2H5v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H5v2h.01v-2ZM9 11v.01h2V11H9Zm1 1.01h.01v-2H10v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM9 15v.01h2V15H9Zm1 1.01h.01v-2H10v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM14 15v.01h2V15h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM14 11v.01h2V11h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM4 15v.01h2V15H4Zm1 1.01h.01v-2H5v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H5v2h.01v-2Z"
                />
              </svg>
              <span>Children</span>
            </div>
            <button
              type="button"
              id="increment-button"
              data-input-counter-increment="children-input"
              onClick={() => handleIncrement("children")}
              className="bg-gray-100 hover:bg-gray-400 border border-gray-300
                  rounded-e-lg p-3 h-11 focus:ring-gray-100  focus:ring-2 focus:outline-none"
            >
              <svg
                className="w-3 h-3 text-gray-900 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 1v16M1 9h16"
                />
              </svg>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default PeopleCounter;
