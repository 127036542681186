export const ROOM_CREATE_API_URL = "/api/v1/cart/cartitems/";

export const MEAL_ADD_API_URL = "/api/v1/cart/cart_meal/";

export const SIGHT_SEEN_API_URL = "/api/v1/cart/cart_custom_tour/";

export const TRANSPORT_API_URL = "/api/v1/cart/cart_transport/"

export const BOOKING_REQUEST_API_URL = "/api/v1/booking_query/"

