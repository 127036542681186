import * as React from "react";

function MainFooter(props) {
  return (
    <div className="flex justify-center items-center px-16 py-12 w-full text-white bg-zinc-900 max-md:px-5 max-md:max-w-full">
      <div className="flex gap-5 justify-between items-start my-6 max-md:flex-wrap max-md:max-w-full">
        <div className="flex flex-col flex-1 text-base leading-6">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3119dae47ce97b2e9e5b146168db7e0d8d550b708692076eb5aa616ba2aa12b7?apiKey=49819ad3d25f4b5f837dad19f5421852&"
            className="self-center aspect-[8.33] w-[315px]"
          />
          <div className="mt-6 text-sm leading-5 whitespace-nowrap text-slate-500">
            Contact Phones:
          </div>
          <a href="callto:+77074445310" className="mt-1 text-lg font-medium leading-6 whitespace-nowrap">
            +7 (707) 444 5310
          </a>
          <a href="callto:+919923387264" className="mt-1 text-lg font-medium leading-6 whitespace-nowrap">
            +91 (992) 338 7264
          </a>
          <a href="mailto:ops@kaz-routes.com" className="mt-3 text-gray-400">Our Email Address.</a>
          <div className="mt-3 font-medium">ops@kaz-routes.com</div>
        </div>
        <div className="flex flex-col flex-1 self-stretch text-sm font-medium leading-5 text-gray-400">
          <div className="text-base leading-6 text-white uppercase">Countries</div>
          <div className="mt-5 hidden">США</div>
          <div className="mt-3 hidden">Бразилия</div>
          <div className="mt-3 hidden">Турция</div>

          <div className="mt-3 hidden">Европа</div>

          <div className="mt-3 ">Azerbaijan</div>
          <div className="mt-3">Kazakhstan</div>
          <div  className="flex gap-2 justify-between py-1.5 pr-1.5 mt-1.5 text-yellow-400 bg-zinc-900 hidden">
            <div className="flex-auto hidden">Browse All Product</div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe5cda440743d93a5c226c7b517ec41058594d56e43fb55e1d9e679d31cb2626?apiKey=49819ad3d25f4b5f837dad19f5421852&"
              className="w-5 aspect-square"
            />
          </div>
        </div>
        <div className="flex flex-col flex-1 self-stretch pb-1.5 text-sm font-medium leading-5 text-gray-400 hidden">
          <div className="text-base leading-6 text-white uppercase whitespace-nowrap">
            быстрые ссылки
          </div>
          <div className="mt-5">Скидки</div>
          <div className="mt-3">Горячие путёвки</div>
          <div className="mt-3">Рекомендуем</div>
          <div className="mt-3">Страны</div>
          <div className="mt-3">Регионы</div>
          <div className="mt-3">Города</div>
          <div className="mt-3">Аппартаменты</div>
        </div>
        <div className="flex flex-col flex-1 text-white whitespace-nowrap hidden">
          <div className="text-base font-medium leading-6 text-white uppercase">
            Скачать приложение
          </div>
          <div className="flex gap-4 justify-between px-6 py-4 mt-5 rounded bg-neutral-700 max-md:px-5">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/d0ffc0e5d9fe6354ed016579fa66e6a139330715bf8f747c54fc3f19d2da1f33?apiKey=49819ad3d25f4b5f837dad19f5421852&"
              className="my-auto w-8 aspect-square"
            />
            <div className="flex flex-col flex-1">
              <div className="text-xs leading-3">Get it now</div>
              <div className="mt-1 text-sm font-semibold leading-5">
                Google Play
              </div>
            </div>
          </div>
          <div className="flex gap-4 justify-between px-6 py-4 mt-3 rounded bg-neutral-700 max-md:px-5">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/7ac50380282c760e40b8b3be7b562e89554796fa003c97568494a0bd8de9e0f5?apiKey=49819ad3d25f4b5f837dad19f5421852&"
              className="my-auto w-8 aspect-square"
            />
            <div className="flex flex-col flex-1">
              <div className="text-xs leading-3">Get it now</div>
              <div className="mt-1 text-sm font-semibold leading-5">
                App Store
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-1 text-sm font-medium leading-5 hidden">
          <div className="text-base leading-6 uppercase whitespace-nowrap">
            Популярные теги
          </div>
          <div className="flex gap-2 mt-5 whitespace-nowrap">
            <div className="justify-center px-3 py-1.5 rounded-sm border border-solid aspect-[2.28] border-[color:var(--Gray-800,#303639)]">
              Турция
            </div>
            <div className="grow justify-center px-3 py-1.5 rounded-sm border border-solid border-[color:var(--Gray-800,#303639)]">
              Казахстан
            </div>
            <div className="justify-center px-3 py-1.5 rounded-sm border border-solid aspect-[2.31] border-[color:var(--Gray-800,#303639)]">
              Скидки
            </div>
            <div className="justify-center px-3 py-1.5 rounded-sm border border-solid aspect-[2.09] border-[color:var(--Gray-800,#303639)]">
              Отели
            </div>
          </div>
          <div className="flex gap-2 pr-20 mt-2 max-md:pr-5">
            <div className="grow justify-center px-3 py-1.5 rounded-sm border border-solid border-[color:var(--Gray-800,#303639)]">
              Стамбул{" "}
            </div>
            <div className="justify-center px-3 py-1.5 whitespace-nowrap rounded-sm border border-solid aspect-[1.78] border-[color:var(--Gray-800,#303639)]">
              США
            </div>
            <div className="grow justify-center px-3 py-1.5 whitespace-nowrap rounded-sm border border-solid bg-neutral-700 border-[color:var(--Gray-00,#FFF)]">
              Бразилия
            </div>
          </div>
          <div className="flex gap-2 pr-20 mt-2 whitespace-nowrap max-md:pr-5">
            <div className="grow justify-center px-3 py-1.5 rounded-sm border border-solid border-[color:var(--Gray-800,#303639)]">
              Ресторан
            </div>
            <div className="grow justify-center px-3 py-1.5 rounded-sm border border-solid border-[color:var(--Gray-800,#303639)]">
              Путёвки
            </div>
            <div className="justify-center px-3 py-1.5 rounded-sm border border-solid aspect-[2.19] border-[color:var(--Gray-800,#303639)]">
              Линии
            </div>
          </div>
          <div className="flex gap-2 pr-16 mt-2 whitespace-nowrap max-md:pr-5">
            <div className="justify-center px-3 py-1.5 rounded-sm border border-solid aspect-[2.06] border-[color:var(--Gray-800,#303639)]">
              Tablet
            </div>
            <div className="grow justify-center px-3 py-1.5 rounded-sm border border-solid border-[color:var(--Gray-800,#303639)]">
              Microwave
            </div>
            <div className="grow justify-center px-3 py-1.5 rounded-sm border border-solid border-[color:var(--Gray-800,#303639)]">
              Samsung
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainFooter;
