import useFetch from "../Components/useFetch";
import React, { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { actions } from "../Actions/cartSlice";

const MealItem = () => {
  const [selected, setSelected] = useState(null);
  const dispatch = useDispatch();
  const {
    data: meals,
    isLoading,
    error,
  } = useFetch(`${process.env.REACT_APP_API_URL}/api/v1/meals/`);
  {
    
  }
  function handleSetSelected(value) {
    setSelected(value);
    localStorage.setItem("SelectedMeal", JSON.stringify(value));
    
    dispatch(actions.updateMeals(value))
  }
  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }
  return (
    <div className="w-full px-4 py-16">
      <div className="mx-auto w-full max-w-lg">
        <RadioGroup
          value={selected}
          onChange={(value) => handleSetSelected(value)}
        >
          <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
          <div className="space-y-2">
            {meals.map((meal, index) => (
              <RadioGroup.Option
                key={index}
                value={meal.id}
                className={({ active, checked }) =>
                  `${
                    active
                      ? "ring-2 ring-white/60 ring-offset-2 ring-offset-sky-300"
                      : ""
                  }
                  ${checked ? "bg-sky-900/75 text-white" : "bg-white"}
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`font-medium  ${
                              checked ? "text-white" : "text-gray-900"
                            }`}
                          >
                            {meal.name}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={`inline ${
                              checked ? "text-sky-100" : "text-gray-500"
                            }`}
                          >
                            {/* <span>
                              {plan.ram}/{plan.cpus}
                            </span>{" "}
                            <span aria-hidden="true">&middot;</span>{" "} */}
                            <span>{meal.meal_type}</span>
                          </RadioGroup.Description>
                        </div>
                      </div>
                      {checked && (
                        <div className="shrink-0 text-white">
                          <CheckIcon className="h-6 w-6" />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  );
};

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default MealItem;
