import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { reset_password } from "../Actions/auth";

const ResetPassword = ({ reset_password }) => {
  const [requestSent, setRequestSent] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });

  const navigate = useNavigate(); // Hook for navigation

  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    // This useEffect will trigger when requestSent changes
    if (requestSent) {
      navigate("/"); // Navigate to the home page
    }
  }, [requestSent, navigate]);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!email.trim()) {
      
      // You can display an error message or take appropriate action
      return;
    }

    await reset_password(email);
    setRequestSent(true);
  };

  //Is user authed > redir to home
  return (
    <div className="flex-col overflow-hidden relative flex min-h-[1080px] justify-center items-center px-16 max-md:px-5" >
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/39e6d2008dc4e0e07e6a9183a7559c1622f22e9242a6b70506fe3eb6fe6e325c?apiKey=49819ad3d25f4b5f837dad19f5421852&"
        className="absolute object-cover object-center inset-0 size-full"
        alt="Background Image"
      />
      <div className="relative shadow-2xl backdrop-blur-[12.300000190734863px] bg-white bg-opacity-50 flex w-[992px] max-w-full flex-col pl-20 pr-10 pt-8 pb-12 items-center max-md:px-5">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/613f6a5fe171bfbf480de9b108b741478be6c1b8fa7181409a63e57f9e15ecf4?apiKey=49819ad3d25f4b5f837dad19f5421852&"
          className="aspect-square object-contain w-6"
          style={{ alignSelf: "end" }}
        />
        <header className="text-slate-900 text-center text-3xl font-bold  mt-96 max-md:max-w-full max-md:mr-2.5 max-md:mt-10">
          Забыли пароль?
        </header>
        <form className="bg-gray-700 flex w-[661px] max-w-full items-stretch justify-between gap-5 mt-10 px-9 py-4 rounded-[80px] max-md:flex-wrap max-md:px-5" action="" onSubmit={(e) => onSubmit(e)} id={"resetpasswordemail"}>
          <div className="text-white text-opacity-90 text-sm grow shrink basis-auto">
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <input
              type="email"
              name={"email"}
              value={email}
              placeholder={"Email"}
              className="form-control w-full outline-none bg-transparent"
              onChange={(e) => onChange(e)}
              required
            />
          </div>
        </form>
        <button
          className="text-zinc-50 text-xl font-medium bg-slate-900 w-[720px] max-w-full justify-center items-center mt-10 mb-64 px-16 py-7 rounded-[80px] max-md:mb-10 max-md:px-5"
          aria-label="Продолжить"
          form={"resetpasswordemail"}
        >
          Продолжить
        </button>
      </div>
    </div>
  );
};

export default connect(null, { reset_password })(ResetPassword);
