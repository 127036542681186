import React, { useState } from "react";
import useFetch from "../Components/useFetch";
import { RadioGroup } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { actions } from "../Actions/cartSlice";

// ... (your imports)

const SightSeenSelect = () => {
    const [selected, setSelected] = useState([]);
    const dispatch = useDispatch();
  
    const { data: sightSeens, isLoading, error } = useFetch(`${process.env.REACT_APP_API_URL}/api/v1/tours/`);
  
    if (isLoading) {
      return <p>Loading...</p>;
    }
  
    if (error) {
      return <p>Error: {error}</p>;
    }
  
    const handleCheckboxChange = (sightSeenId) => {
      setSelected((prevSelected) => {
        let updatedSelected;
        if (prevSelected.includes(sightSeenId)) {
          // If already selected, remove it
          updatedSelected = prevSelected.filter((id) => id !== sightSeenId);
        } else {
          // If not selected, add it
          updatedSelected = [...prevSelected, sightSeenId];
        }
        // Update local storage
        localStorage.setItem("selectedSightSeens", JSON.stringify(updatedSelected));
        dispatch(actions.addSightSeen(updatedSelected))
        return updatedSelected;
      });
    };
  
    
    return (
      <div>
        <h3 className="mb-5 text-lg font-medium text-gray-900 dark:text-white ">
          Choose sightseeing:
        </h3>
        <div className="overflow-y-auto max-h-[40rem]">
          <ul className="flex flex-col w-full gap-6 md:grid-cols-3">
            {sightSeens.map((sightSeen, index) => (
              <li key={index}>
                <input
                  type="checkbox"
                  id={`sightseen-${sightSeen.id}`}
                  value={sightSeen.id}
                  className="hidden peer"
                  required=""
                  checked={selected.includes(sightSeen.id)}
                  onChange={() => handleCheckboxChange(sightSeen.id)}
                />
                <label
                  htmlFor={`sightseen-${sightSeen.id}`}
                  className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div className="block">
                    <div className="w-full text-lg font-semibold">{sightSeen.name}</div>
                    <div className="w-full text-sm">{sightSeen.city}</div>
                    <div className="w-full text-sm">{sightSeen.price} $</div>
                    <div className="w-full text-sm">{sightSeen.description}</div>
                  </div>
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };
  
  export default SightSeenSelect;
  