import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ImageGalleryCustom({ images }) {
  const [open, setOpen] = useState(false);
  const [currIndex, setCurrIndex] = useState(0);

  const handleOpen = (index) => {
    setCurrIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const showNextImg = () => {
    setCurrIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const showPrevImg = () => {
    setCurrIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div>
      {images.map((image, index) => (
        <React.Fragment key={index}>
          <Button onClick={() => handleOpen(index)}>
            <img src={image} alt="" className="w-20 aspect-square" />
          </Button>
        </React.Fragment>
      ))}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: "1vp" }}>
          <div className="flex flex-row">
            <button onClick={showPrevImg}>
              <div className="self-center w-[20px] text-center">{"<"}</div>
            </button>
            <img
              src={images[currIndex]}
              alt=""
              className="w-full h-full aspect-square"
            />
            <button onClick={showNextImg}>
              <div className="self-center w-[20px] text-center">{">"}</div>
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
