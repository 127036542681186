import React from "react";

function NotFoundPage() {
  return (
    <div className="bg-white flex flex-col justify-center items-stretch">
      <header className="flex w-full flex-col justify-center items-center px-16 py-12 rounded-xl max-md:max-w-full max-md:px-5">
        <div className="flex flex-col items-center text-slate-900 font-bold text-center mt-80 mb-64 max-md:max-w-full max-md:my-10">
          <h1 className="text-[180px] leading-[160.2px] max-md:text-4xl" aria-label="404">404</h1>
          <h2 className="text-5xl leading-[55.02px] tracking-tight self-stretch mt-11 max-md:max-w-full max-md:mt-10" aria-label="Страница временно не работает!">
            Страница не найдена!
          </h2>
          <p className="text-lg leading-8 mt-20 max-md:mt-10" aria-label="Пожалуйста, посетите страницу позднее">
            Пожалуйста, попробуйте еще раз!
          </p>
        </div>
      </header>
    </div>
  );
}

export default NotFoundPage;