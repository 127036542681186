// CartContext.js
import React, { createContext, useContext, useState } from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartData, setCartData] = useState([]);

  const addToCart = (data) => {
    setCartData((prevData) => [...prevData, data]);
  };

  const clearCart = () => {
    setCartData([]);
  };

  return (
    <CartContext.Provider value={{ cartData, addToCart, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};
