import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

function SwiperActivity({TourOffers}) {
  return (
    <>
      <div className="flex flex-col items-center px-16 pb-12 w-full max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col my-6 w-full max-w-[1362px] max-md:max-w-full p-8">
          <header className="flex flex-col mb-12 w-full max-w-[1362px] max-md:mb-10 max-md:max-w-full pb-8">
            <div className="flex gap-5 justify-between px-px w-full font-semibold whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
              <div className="flex-auto text-2xl leading-8 text-zinc-900 text-left">
                {" "}
                Активности{" "}
              </div>
              <div className="flex gap-2 justify-between py-1.5 text-sm leading-5 text-sky-400 bg-white hidden">
                <a href="#" className="grow">
                  Browse All Product
                </a>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/7eb3f5f174abced25d2228ee5474be3ef833aa7325e339e9587b04f178e8f5c3?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                  className="w-5 aspect-square"
                />
              </div>
            </div>
          </header>
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              300: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
              1320: {
                slidesPerView: 6,
                spaceBetween: 40,
              },
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <div className="px-px mt-16 max-md:mt-10 max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
                <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
                  {TourOffers.map((_, i) => (
                    <SwiperSlide key={i}>
                      <a href="#contact_form">
                      <div className="flex flex-col grow text-base font-medium leading-6 text-neutral-900 max-md:mt-8">
                        <img
                          loading="lazy"
                          srcSet={_.cover_image.replace("http://", "https://")}
                          className="w-full aspect-[0.83] rounded"
                        />
                        <div className="mt-4">{_.name}</div>
                      </div>
                      </a>
                    </SwiperSlide>
                  ))}
                </div>
              </div>
            </div>
          </Swiper>
        </div>
      </div>
    </>
  );
}
export default SwiperActivity;
