import FPpark from "../../media/FPpark.jpg";
import Rio from "../../media/rio.jpg";
import React from "react";
import Bao from "../../media/BAO.jpg";
import Shym from "../../media/shymbolak.jpeg";
import Hotel from "../../media/hotel.svg";
import Transport from "../../media/car-transport-navigation-svgrepo-com.svg";
import Tour from "../../media/temple-svgrepo-com.svg";
import Guide from "../../media/guide-human-svgrepo-com.svg";

const Devider = () => {
  return (
    <div className="divider flex flex-col justify-center px-0.5 my-auto max-md:hidden">
      <div className="shrink-0 bg-gray-400 h-[46px] w-[1px]" />
    </div>
  );
};

function Header() {
  return (
    <div className="flex flex-col justify-center ">
      <div className="flex gap-8 self-center   mt-6 max-w-full  rounded-md shadow-sm w-[1320px] max-md:flex-wrap ">
        <div
          className="flex flex-col grow items-start  w-full rounded-md shadow-sm max-md:px-5 max-md:mt-6 max-md:max-w-full rounded "
          style={{
            backgroundImage: `url(${FPpark})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="bg-gradient-to-r from-black to-transparent h-full px-14 py-12 text-left">
            <header className="flex gap-2 justify-center mt-20 text-sm font-semibold leading-5 text-white whitespace-nowrap max-md:mt-10 ">
              <span className="my-auto w-6 h-0.5 bg-white" />
              <div className="grow text-left">Tour</div>
            </header>
            <h1 className="mt-1 text-5xl font-semibold text-white leading-[56px] w-[356px] max-md:text-4xl max-md:leading-[52px]">
              Almaty, Kazakhstan
            </h1>
            <a
              href="#"
              className="mt-4 text-lg font-semibold leading-6 text-white whitespace-nowrap"
            >
              More info
            </a>
            <div className="flex gap-3 justify-center px-16 mt-6 text-base font-bold tracking-normal text-white uppercase whitespace-nowrap bg-orange-400 rounded leading-[56px] max-md:px-5 w-[250px]">
              <button type="button" className="flex-auto">
                Learn More
              </button>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/a5b1ffe29b68895324ddf197d8ed231c0dff565b516fd161dc7f990933da2c9e?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                className="my-auto w-6 aspect-square"
                alt=""
              />
            </div>
          </div>
          {/* <div className="flex gap-2 mt-20 w-[46px] max-md:mt-10">
            <div className="flex-1 shrink-0 h-2.5 bg-white rounded-full" />
            <div className="flex-1 shrink-0 h-2.5 bg-gray-400 rounded-full" />
            <div className="flex-1 shrink-0 h-2.5 bg-gray-400 rounded-full" />
          </div> */}
        </div>
        <div className="flex flex-col max-md:w-full">
          <>
            <header
              className="flex flex-col grow max-md:mt-6 max-md:max-w-full rounded"
              style={{
                backgroundImage: `url(${Bao})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="pr-6 rounded-md shadow-sm max-md:pr-5 max-md:max-w-full text-left bg-gradient-to-r from-[#0000009e] to-transparent">
                <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
                  <div className="flex flex-col w-[72%] max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col grow px-10 py-11 mx-auto w-full text-white max-md:px-5 max-md:mt-9">
                      <div className="text-sm font-medium leading-5 text-yellow-400 uppercase">
                        Almaty
                      </div>
                      <div className="mt-1.5 text-2xl font-semibold leading-8">
                        Big Almaty Lake
                      </div>
                      <div className="flex gap-2 justify-between px-6 mt-5 text-base font-bold tracking-normal uppercase whitespace-nowrap bg-orange-400 rounded-sm leading-[56px] max-md:px-5">
                        <a
                          href="#"
                          className="grow"
                          aria-label="Подробнее"
                          role="button"
                        >
                          Learn More
                        </a>
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/229f0f0e6aee1b2fa976a6842eda4e9b570ee38142380e004cac0094f4aae2f0?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                          className="my-auto w-5 aspect-square mr-4"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col ml-5 w-[28%] max-md:ml-0 max-md:w-full">
                    <div className="justify-center px-4 py-2 mt-6 w-full text-base font-semibold leading-6 whitespace-nowrap bg-amber-300 rounded-sm text-neutral-900 max-md:mt-10">
                      29% OFF
                    </div>
                  </div>
                </div>
              </div>
            </header>

            <header
              className="flex flex-col grow max-md:mt-6 max-md:max-w-full rounded mt-6"
              style={{
                backgroundImage: `url(${Shym})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="pr-6 rounded-md shadow-sm max-md:pr-5 max-md:max-w-full text-left bg-gradient-to-r from-[#0000009e] to-transparent">
                <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
                  <div className="flex flex-col w-[60%] max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col grow px-10 py-11 mx-auto text-white max-md:px-5 max-md:mt-9">
                      <div className="text-sm font-medium leading-5 text-yellow-400 uppercase">
                        Almaty
                      </div>
                      <div className="mt-1.5 text-2xl font-semibold leading-8">
                        Shymbulak resort
                      </div>
                      <div className="flex gap-2 justify-between px-6 mt-5 text-base font-bold tracking-normal uppercase whitespace-nowrap bg-orange-400 rounded-sm leading-[56px] max-md:px-5">
                        <a
                          href="#"
                          className="grow"
                          aria-label="Подробнее"
                          role="button"
                        >
                          Learn More
                        </a>
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/229f0f0e6aee1b2fa976a6842eda4e9b570ee38142380e004cac0094f4aae2f0?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                          className="my-auto w-5 aspect-square mr-4"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
          </>
        </div>
      </div>

      <section className="justify-center self-center p-4 mt-6 w-full bg-cyan-700 rounded-md border border-solid shadow-sm border-[color:var(--Gray-100,#E4E7E9)] max-w-[1320px] max-md:max-w-full text-left">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md-">
          <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
            <div className="flex grow gap-4 justify-between p-4 text-sm text-white max-md:mt-10">
              <img
                loading="lazy"
                src={Hotel}
                className="my-auto w-10 aspect-square"
                alt="Best Flights"
              />
              <div className="flex flex-col flex-1">
                <h2 className="font-medium uppercase whitespace-nowrap leading-[143%]">
                  Hotels
                </h2>
                <p className="mt-1 leading-5">
                  Indulge in luxurious comfort with breathtaking views and
                  impeccable service.
                </p>
              </div>
            </div>
          </div>
          <Devider />
          <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
            <div className="flex grow gap-4 justify-between p-4 text-sm text-white max-md:mt-10">
              <img
                loading="lazy"
                src={Transport}
                className="my-auto w-10 aspect-square"
                alt="Weather Forecast"
              />
              <div className="flex flex-col flex-1">
                <h2 className="font-medium uppercase whitespace-nowrap leading-[143%]">
                  Transportation
                </h2>
                <p className="mt-1 leading-5">
                  Discover scenic routes and enjoy seamless airport transfers in
                  comfortable vehicles.
                </p>
              </div>
            </div>
          </div>
          <Devider />

          <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
            <div className="flex grow gap-4 justify-between p-4 text-sm text-white max-md:mt-10">
              <img
                loading="lazy"
                src={Tour}
                className="my-auto w-10 aspect-square"
                alt="Local Events"
              />
              <div className="flex flex-col flex-1">
                <h2 className="font-medium uppercase whitespace-nowrap leading-[143%]">
                  Tours
                </h2>
                <p className="mt-1 leading-5">
                  Experience cultural immersion through local cuisine and
                  interactive workshops.
                </p>
              </div>
            </div>
          </div>
          <Devider />

          <div className="flex flex-col ml-5 w-3/12 max-md:ml-0 max-md:w-full">
            <div className="flex grow gap-4 justify-between p-4 text-sm text-white max-md:mt-10">
              <img
                loading="lazy"
                src={Guide}
                className="my-auto w-10 aspect-square"
                alt="Settings"
              />
              <div className="flex flex-col flex-1">
                <h2 className="font-medium uppercase leading-[143%]">Guides</h2>
                <p className="mt-1 leading-5">
                  Embark on immersive experiences with knowledgeable
                  multilingual guides for seamless communication.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Header;
