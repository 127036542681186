import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { reset_password_confirm } from "../Actions/auth";

const ResetPasswordConfirm = ({ reset_password_confirm }) => {
  const [formData, setFormData] = useState({
    new_password: "",
    re_new_password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const { uid, token } = useParams();
  const navigate = useNavigate();

  const { new_password, re_new_password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      await reset_password_confirm(uid, token, new_password, re_new_password);

      // Use a Promise to wait for the state update
      await new Promise((resolve) => {
        setFormData({
          new_password: "", // Reset the form fields if needed
          re_new_password: "",
        });
        resolve();
      });

      // Use another Promise to wait for the navigation
      await new Promise((resolve) => {
        navigate("/Password-Reset-Success");
        resolve();
      });
    } catch (error) {
      // Handle any errors from the reset_password_confirm action
      console.error("Password reset confirmation failed:", error);
    }
  };

  return (
    <form id={"password-reset-input-form"} className="flex-col overflow-hidden relative flex min-h-[1080px] justify-center items-center px-16 max-md:px-5" action="" onSubmit={(e) => onSubmit(e)}>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/8d84410f7f9697c50ebf52b0d87d6665f7d83c24d51ad2435387a32f5b53d841?apiKey=49819ad3d25f4b5f837dad19f5421852&"
        className="absolute object-cover object-center inset-0 size-full"
      />
      <header className="relative shadow-2xl backdrop-blur-[12.300000190734863px] bg-white bg-opacity-50 flex w-[992px] max-w-full flex-col pl-20 pr-10 pt-8 pb-12 items-center max-md:px-5">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/613f6a5fe171bfbf480de9b108b741478be6c1b8fa7181409a63e57f9e15ecf4?apiKey=49819ad3d25f4b5f837dad19f5421852&"
          className="aspect-square object-contain w-6"
          style={{ alignSelf: "end" }}
        />
        <h1 className="text-slate-900 text-center text-3xl font-bold  mt-80 max-md:max-w-full max-md:mr-2.5 max-md:mt-10">
          Повторите пароль
        </h1>

        {/* New Password input */}
        <div className="bg-gray-700 flex w-[661px] max-w-full items-stretch justify-between gap-5 mt-10 px-9 py-4 rounded-[80px] max-md:flex-wrap max-md:px-5">
          <div className="text-white text-opacity-90 text-sm grow shrink basis-auto">
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <input
              className="form-control w-full outline-none bg-transparent"
              type={showPassword ? "text" : "password"}
              placeholder="New Password"
              name="new_password"
              value={new_password}
              onChange={(e) => onChange(e)}
              minLength="6"
              required
            />
          </div>
          <button onClick={(e) => handleShowPassword(e)}>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/2e578726495071397eec256a9cdafcebacc5787369e9aff3e10256b793f26e66?apiKey=49819ad3d25f4b5f837dad19f5421852&"
              className="aspect-[1.19] object-contain object-center w-[19px] fill-white fill-opacity-80 shrink-0"
              alt="arrow"
            />
          </button>
        </div>

        {/* Confirm New Password input */}
        <div className="bg-gray-700 flex w-[661px] max-w-full items-stretch justify-between gap-5 mt-10 px-9 py-4 rounded-[80px] max-md:flex-wrap max-md:px-5">
          <div className="text-white text-opacity-90 text-sm grow shrink basis-auto">
            <label htmlFor="password" className="sr-only">
              Password
            </label>
            <input
              className="form-control w-full outline-none bg-transparent"
              type={showPassword ? "text" : "password"}
              placeholder="Confirm New Password"
              name="re_new_password"
              value={re_new_password}
              onChange={(e) => onChange(e)}
              minLength="6"
              required
            />
          </div>
          <button onClick={(e) => handleShowPassword(e)}>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/2e578726495071397eec256a9cdafcebacc5787369e9aff3e10256b793f26e66?apiKey=49819ad3d25f4b5f837dad19f5421852&"
              className="aspect-[1.19] object-contain object-center w-[19px] fill-white fill-opacity-80 shrink-0"
              alt="arrow"
            />
          </button>
        </div>

        {/* Continue button */}
        <button
        type="submit"
          className="text-zinc-50 text-xl font-medium bg-slate-900 w-[720px] max-w-full justify-center items-center mt-10 mb-64 px-16 py-7 rounded-[80px] max-md:mb-10 max-md:px-5"
          aria-label="Продолжить"
          form={"password-reset-input-form"}
        >
          Продолжить
        </button>
      </header>
    </form>
  );
};

export default connect(null, { reset_password_confirm })(ResetPasswordConfirm);
