import * as React from "react";
import FilterColumn from "../Components/Filter/FilterColumn";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import useFetch from "../Components/useFetch";
import { orange } from "@mui/material/colors";
import StarRating from "./StarRating";
import { useState } from "react";
import usePagination from "@mui/material/usePagination/usePagination";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const MAX_DESCRIPTION_LENGTH = 500;
const ITEMS_PER_PAGE = 3;

const SearchResultsPage = () => {
  const count = useSelector((state) => state.countrySlice.country.country);
  
  // const [page, setPage] = useState(1);
  let selectedCountry = JSON.parse(
    localStorage.getItem("SelectedCountryForSearch")
  );
  // 
  const currPage = useLocation();
  // 
  const {
    data: hotels,
    isLoading,
    error,
  } = useFetch(
    `${process.env.REACT_APP_API_URL}/api/v1/hotels/?location_country=${
      count ? count.id : 1
    }`
  );
  const truncateDescription = (description) => {
    if (description.length > MAX_DESCRIPTION_LENGTH) {
      return `${description.substring(0, MAX_DESCRIPTION_LENGTH)}...`;
    }
    return description;
  };
  // const _DATA = usePagination(hotels || [], ITEMS_PER_PAGE);
  // const handlePageChange = (event, value) => {
  //   setPage(value);
  //   _DATA.jump(value);
  // };
  return (
    <div className="flex flex-col justify-center">
      <div className="self-center py-11 w-full max-w-[1320px] max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
          <div className="flex flex-col w-[24%] max-md:ml-0 max-md:w-full">
            <div className=" text-zinc-900">
              FILTER OPTIONS SOON TO BE ADDED
            </div>
            <div className="blur-sm">
              <FilterColumn />
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[76%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col max-md:mt-10 max-md:max-w-full">
              <div className="flex gap-5 justify-between px-px w-full text-sm leading-5 whitespace-nowrap max-md:flex-wrap max-md:max-w-full">
                <div className="justify-center py-3 pr-11 pl-4 bg-white rounded-sm border border-solid border-[color:var(--Gray-100,#E4E7E9)] text-slate-500 max-md:pr-5">
                  Услуги отеля
                </div>
                <div className="flex gap-5 justify-between px-5">
                  <div className="grow my-auto text-zinc-900">Сортировать:</div>
                  <div className="flex gap-5 justify-between px-4 py-3.5 bg-white rounded-sm border border-solid border-[color:var(--Gray-100,#E4E7E9)] text-neutral-600">
                    <div>Популярности</div>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/8e3b5e0d925b162b1fedc1da4770082312089e8a1dd9e17e8885a209d8290377?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                      className="w-4 aspect-square"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center items-start py-3 pr-16 pl-6 mt-4 text-sm leading-5 whitespace-nowrap bg-gray-100 rounded text-zinc-900 max-md:px-5 max-md:max-w-full">
                <div className="flex gap-4">
                  <div className="grow text-gray-500">Категории:</div>
                  <div className="flex gap-1.5 justify-between">
                    <div className="grow">Страны</div>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ea140831b2c3e2e39953923d22a773c52d5f6c456386c5a0d1c2e383b8095fd?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                      className="my-auto w-3 aspect-square"
                    />
                  </div>
                  <div className="flex gap-1.5 justify-between">
                    <div className="grow">Турция</div>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ea140831b2c3e2e39953923d22a773c52d5f6c456386c5a0d1c2e383b8095fd?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                      className="my-auto w-3 aspect-square"
                    />
                  </div>
                  <div className="flex gap-1.5 justify-between">
                    <div className="grow">Отели</div>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ea140831b2c3e2e39953923d22a773c52d5f6c456386c5a0d1c2e383b8095fd?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                      className="my-auto w-3 aspect-square"
                    />
                  </div>
                </div>
              </div>
              {error && <div>{error}</div>}
              {isLoading ? (
                <div className="loading-preview">
                  <div id="loader"></div>
                  <h2>Loading...</h2>
                </div>
              ) : (
                hotels.map((hotel) => (
                  <Link to={`/hotel/${hotel.id}`} key={hotel.id}>
                    <article
                      key={hotel.id}
                      className="pr-7 p-4 mt-6 bg-white rounded-xl border border-solid border-[color:var(---,#E1E1E1)] max-md:pr-5 max-md:max-w-full text-left"
                      aria-label="Hotel"
                    >
                      <div
                        className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:"
                        role="group"
                      >
                        <div className="flex flex-col w-2/5 max-md:ml-0 max-md:w-full">
                          {hotel.cover_image ? (
                            <img
                              src={hotel.cover_image}
                              alt={hotel.base_hotel.name}
                              className="grow w-full rounded-lg aspect-[1.5]"
                            />
                          ) : (
                            <img
                              src={
                                window.location.origin + "/img-not-found.png"
                              }
                              alt={hotel.base_hotel.name}
                              className="grow w-full rounded-lg aspect-[1.5]"
                            />
                          )}
                        </div>
                        <div className="flex flex-col ml-5 w-3/5 max-md:ml-0 max-md:w-full">
                          <div className="flex flex-col self-stretch my-auto text-sm text-gray-400 max-md:mt-8 max-md:max-w-full">
                            <div className="flex gap-1 self-start text-xs leading-4 whitespace-nowrap">
                              <StarRating
                                starCount={hotel.stars}
                                starColor="#FFA800"
                                className=" w-4 aspect-square"
                              />
                              <div className="grow ">(2738)</div>
                            </div>
                            <h1 className="mt-2 text-base font-bold text-zinc-900 max-md:max-w-full">
                              {hotel.base_hotel.name}
                            </h1>
                            <div className="mt-2 font-semibold text-sky-400 leading-[143%] max-md:max-w-full">
                              {hotel.location_city.country.name},{" "}
                              {hotel.location_city.name}
                            </div>
                            <p className="mt-2 leading-5 text-zinc-900 max-md:max-w-full">
                              {truncateDescription(hotel.description)}
                            </p>
                            <div className="text-base font-medium leading-6 text-neutral-900 max-md:mt-10">
                              {hotel.hotel_features
                                .slice(0, 3)
                                .map((feature, index, array) =>
                                  index === array.length - 1
                                    ? feature.name + "."
                                    : feature.name + ", "
                                )}
                            </div>
                            <div className="flex gap-5 justify-between mt-7 font-medium leading-5 max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/a85d0e33771053a5a0d1a5e3ca47be29c038cdaf580507018bc5adc1985c7cab?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                                className="w-8 aspect-square"
                                alt="Currency"
                              />
                              <div className="flex-auto my-auto text-right">
                                <span className="text-xs text-gray-400">
                                  Starting from{" "}
                                </span>
                                <span className="text-2xl text-black">
                                  450 123
                                </span>{" "}
                                <span className="text-xs text-gray-400">
                                  тнг{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                  </Link>
                ))
              )}

              <div className="flex gap-5 justify-center mt-20 max-md:mt-10 max-md:ml-2.5">
                <div className="flex gap-2 text-sm leading-5 text-center whitespace-nowrap text-zinc-900">
                  {/* {hotels && (
                    <Pagination
                      count={Math.ceil(hotels.length / ITEMS_PER_PAGE)}
                      color="primary"
                      page={page}
                      onChange={handlePageChange}
                    />
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResultsPage;
