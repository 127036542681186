import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as faStarFull } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";

const StarRating = ({ starCount, starColor }) => {
  const stars = [];

  for (let i = 1; i <= 5; i++) {
    if (i <= starCount) {
      stars.push(<FontAwesomeIcon icon={faStarFull} key={i} style={{ color: starColor }} />);
    } else {
      stars.push(<FontAwesomeIcon icon={faStarRegular} key={i} style={{ color: starColor }} />);
    }
  }

  return <div className={"hotelstars"}>{stars}</div>;
};

export default StarRating;
