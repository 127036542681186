import React from "react";

function CallCenterCard() {
  return (
    <div className="grow p-8 w-full bg-white rounded shadow-2xl max-md:px-5 max-md:mt-6 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-[21%] max-md:ml-0 max-md:w-full">
          <div className="flex justify-center items-center px-6 mx-auto w-24 h-24 bg-white rounded max-md:px-5 max-md:mt-10">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/3233d5ec4e79fe6708bf33cd2ea37b320463c2394390089f75f38e8f3453f194?apiKey=49819ad3d25f4b5f837dad19f5421852&" alt="Call center icon" className="w-12 aspect-square" />
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[79%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow text-sm leading-5 text-zinc-900 max-md:mt-10">
            <h3 className="text-lg font-semibold">Call us</h3>
            <p className="mt-2 text-gray-400 leading-[143%]">
              We are always happy to recieve your calls!
            </p>
            <p className="mt-4 text-2xl">+7-707-444-5310</p>
            <a href="callto:+77074445310" className="flex gap-2 justify-center px-6 mt-6 font-bold tracking-normal leading-10 text-white uppercase whitespace-nowrap bg-sky-400 rounded-sm max-md:px-5">
              <span>Place a call!</span>
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/229f0f0e6aee1b2fa976a6842eda4e9b570ee38142380e004cac0094f4aae2f0?apiKey=49819ad3d25f4b5f837dad19f5421852&" alt="Phone icon" className="shrink-0 my-auto w-5 aspect-square" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function MessageUsCard() {
  return (
    <div className="grow p-8 w-full bg-white rounded shadow-2xl max-md:px-5 max-md:mt-6 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-[21%] max-md:ml-0 max-md:w-full">
          <div className="flex justify-center items-center px-6 mx-auto w-24 h-24 bg-gray-100 rounded max-md:px-5 max-md:mt-10">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/7593b31c5c687b8984d3daf9302093aed1986954875c0ed4cc24fec9e75d6242?apiKey=49819ad3d25f4b5f837dad19f5421852&" alt="Message icon" className="w-12 aspect-square" />
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[79%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow text-sm leading-5 text-zinc-900 max-md:mt-10">
            <h3 className="text-lg font-semibold">Message us!</h3>
            <p className="mt-2 text-gray-400 leading-[143%]">
              24/7 Support
            </p>
            <p className="mt-4 text-2xl">ops@kaz-routes.com</p>
            <a href="mailto:ops@kaz-routes.com" className="flex gap-2 justify-center px-6 mt-6 font-bold tracking-normal leading-10 text-white uppercase whitespace-nowrap bg-green-600 rounded-sm max-md:px-5">
              <span>Send an Email!</span>
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/229f0f0e6aee1b2fa976a6842eda4e9b570ee38142380e004cac0094f4aae2f0?apiKey=49819ad3d25f4b5f837dad19f5421852&" alt="Message icon" className="shrink-0 my-auto w-5 aspect-square" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function ContactUS() {
  return (
    <section className="flex justify-center items-center px-16 py-20 bg-gray-100 max-md:px-5">
      <div className="flex flex-col max-w-full w-[1096px]">
        <div className="justify-center self-center px-4 py-2 text-sm font-semibold leading-5 text-white bg-sky-400 rounded-sm">
          Contact us
        </div>
        <div className="mt-16 max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <CallCenterCard />
            </div>
            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
              <MessageUsCard />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUS;
