import { useState } from "react";
const Counter = ({ onAdultChange, onChildChange }) => {
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
// 
// 

const handleIncrement = (inputName) => {
    switch (inputName) {
      case "adults":
        setAdults((prevAdults) => {
          const updatedValue = prevAdults + 1;
          
          
          onAdultChange(updatedValue);
          return updatedValue;
        });
        break;
      case "children":
        setChildren((prevChildren) => {
          const updatedValue = prevChildren + 1;
          
          
          onChildChange(updatedValue);
          return updatedValue;
        });
        break;
      default:
        break;
    }
  };
  
  const handleDecrement = (inputName) => {
    switch (inputName) {
      case "adults":
        setAdults((prevAdults) => {
          const updatedValue = Math.max(prevAdults - 1, 1);
          onAdultChange(updatedValue);
          return updatedValue;
        });
        break;
      case "children":
        setChildren((prevChildren) => {
          const updatedValue = Math.max(prevChildren - 1, 0);
          onChildChange(updatedValue);
          return updatedValue;
        });
        break;
      default:
        break;
    }
  };
  
  return (
    <div className="mx-auto w-full max-w-lg py-2">
      <div className="relative flex gap-4 items-center justify-center relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none bg-white">
        <div className={"font-medium uppercase text-sm"}>
          Please Input Person Count
        </div>
        <div className="flex flex-col ">
          <span className="text-xs text-gray-400 space-x-1">Adult</span>
          <div className="flex flex-row">
            <button
              type="button"
              id="decrement-button"
              onClick={() => handleDecrement("adults")}
              data-input-counter-decrement="adults-input"
              className="bg-gray-100 hover:bg-gray-400 border border-gray-300 
  rounded-s-lg p-3 h-11 focus:ring-gray-100  focus:ring-2 focus:outline-none"
            >
              <svg
                className="w-3 h-3 text-gray-900 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 2"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h16"
                />
              </svg>
            </button>
            <input
              type="text"
              id="adults-input"
              data-input-counter
              data-input-counter-min="1"
              className="bg-transparent border-y-2 border-gray-200 h-11 font-medium text-center text-gray-900 text-sm 
   block w-[50px]  outline-none"
              placeholder=""
              value={adults}
              onChange={(e) => {
                const inputValue = e.target.value.trim(); // Trim whitespace
                if (inputValue === "") {
                  // Handle case where the input is empty
                  setAdults(null); // or set it to another default value if needed
                } else {
                  const parsedValue = parseInt(inputValue, 10);
                  if (!isNaN(parsedValue)) {
                    setAdults(parsedValue);
                    onAdultChange(parsedValue);
                  } else {
                    // Handle the case where the input is not a valid integer
                    console.error("Invalid integer input");
                  }
                }
              }}
              required
            />

            <button
              type="button"
              id="increment-button"
              data-input-counter-increment="adults-input"
              onClick={() => handleIncrement("adults")}
              className="bg-gray-100 hover:bg-gray-400 border border-gray-300
  rounded-e-lg p-3 h-11 focus:ring-gray-100  focus:ring-2 focus:outline-none"
            >
              <svg
                className="w-3 h-3 text-gray-900 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 1v16M1 9h16"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="flex flex-col">
          <span className="text-xs text-gray-400 space-x-1">Children</span>
          <div className="flex flex-row">
            <button
              type="button"
              id="decrement-button"
              data-input-counter-decrement="children-input"
              onClick={() => handleDecrement("children")}
              className="bg-gray-100 hover:bg-gray-400 border border-gray-300 
                  rounded-s-lg p-3 h-11 focus:ring-gray-100  focus:ring-2 focus:outline-none"
            >
              <svg
                className="w-3 h-3 text-gray-900"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 2"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h16"
                />
              </svg>
            </button>
            <input
              type="text"
              id="children-input"
              data-input-counter
              data-input-counter-min="0"
              className="bg-transparent border-y-2 border-gray-200 h-11 font-medium text-center text-gray-900 text-sm 
                   block w-[50px]  outline-none"
              placeholder=""
              value={children}
              onChange={(e) => {
                const inputValue = e.target.value.trim(); // Trim whitespace
                if (inputValue === "") {
                  // Handle case where the input is empty
                  setChildren(null); // or set it to another default value if needed
                } else {
                  const parsedValue = parseInt(inputValue, 10);
                  if (!isNaN(parsedValue)) {
                    setChildren(parsedValue);
                    onChildChange(parsedValue);
                  } else {
                    // Handle the case where the input is not a valid integer
                    console.error("Invalid integer input");
                  }
                }
              }}
              required
            />

            <button
              type="button"
              id="increment-button"
              data-input-counter-increment="children-input"
              onClick={() => handleIncrement("children")}
              className="bg-gray-100 hover:bg-gray-400 border border-gray-300
                  rounded-e-lg p-3 h-11 focus:ring-gray-100  focus:ring-2 focus:outline-none"
            >
              <svg
                className="w-3 h-3 text-gray-900 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 1v16M1 9h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Counter;
