export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const USER_LOADED_SUCCESS = "USER_LOADED_SUCCESS";
export const USER_LOADED_FAIL = "USER_LOADED_FAIL";

export const AUTHENTICATED_SUCCESS = "AUTHENTICATED_SUCCESS";

export const AUTHENTICATED_FAIL = "AUTHENTICATED_FAIL";

export const LOGOUT = "LOGOUT";

export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";

export const PASSWORD_RESET_FAIL = "PASSWORD_RESET_FAIL";

export const PASSWORD_RESET_CONFIRM_SUCCESS = "PASSWORD_RESET_CONFIRM_SUCCESS";

export const PASSWORD_RESET_CONFIRM_FAIL = "PASSWORD_RESET_CONFIRM_FAIL";

export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";

export const CREATE_ACCOUNT_FAIL = "CREATE_ACCOUNT_FAIL";

export const CREATE_ACCOUNT_CONFIRM_SUCCESS = "CREATE_ACCOUNT_CONFIRM_SUCCESS";

export const CREATE_ACCOUNT_CONFIRM_FAIL = "CREATE_ACCOUNT_CONFIRM_FAIL";
