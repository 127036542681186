import React, { useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";

const DatePick = ({onDateChange}) => {
  const [value, setValue] = useState({});

  const handleValueChange = (newValue) => {
    
    setValue(newValue);
    onDateChange(newValue);
  };

  return (
    <Datepicker
      placeholder="PICK DATES"
      primaryColor={"orange"}
      minDate={new Date()}
      maxDate={new Date().setFullYear(new Date().getFullYear() + 1)}
      useRange={false}
      value={value}
      onChange={handleValueChange}
      //   inputClassName="placeholder-neutral-900 text-base font-medium leading-6 text-neutral-900 text-center w-full outline-none bg-white hover:cursor-pointer"
    />
  );
};

export default DatePick;
