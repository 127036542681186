// SelectSearch.js
import React, { useState, useEffect } from "react";
import Select from "react-select";
import useFetch from "../Components/useFetch";

const SelectSearch = ({ onValueChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const {
    data: options,
    isLoading,
    error,
  } = useFetch(`${process.env.REACT_APP_API_URL}/api/v1/countries/`);

  if (isLoading) {
    return (
      <div className="loading-preview">
        <div id="loader"></div>
        <h2>Loading...</h2>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const formattedOptions = options.map((country) => ({
    label: country.name,
    value: country.id,
  }));

  const handleSelectChange = (selected) => {
    setSelectedOption(selected);
    onValueChange(selected); // Pass the selected value to the parent component or another function
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleSelectChange}
      options={formattedOptions}
      placeholder="Select Country"
      isClearable={true}
    />
  );
};

export default SelectSearch;
