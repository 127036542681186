import * as React from "react";

function SubscriptionFooter() {
  return (
    <header className="flex justify-center items-center px-16 py-12 w-full bg-cyan-700 max-md:px-5 max-md:max-w-full">
      <div className="flex flex-col items-center my-6 w-full max-w-[1320px] max-md:max-w-full">
        <h1 className="self-stretch text-3xl font-semibold text-center text-white max-md:max-w-full">
          Следите за нами!
        </h1>
        <div className="flex gap-4 p-3 mt-8 max-w-full whitespace-nowrap bg-white rounded-md shadow-lg w-[700px] max-md:flex-wrap">
          <div className="flex-grow justify-center items-start py-5 pr-16 pl-4 text-base leading-6 bg-white rounded-sm text-slate-500 max-md:pr-5 max-md:max-w-full">
            <label htmlFor="email" className="sr-only">Email address</label>
            <input
              type="email"
              id="email"
              className="w-full outline-none"
              placeholder="Email address"
              aria-label="Email address"
            />
          </div>
          <button
            type="submit"
            className="flex gap-2 justify-between px-6 text-sm font-bold tracking-normal leading-10 text-white uppercase bg-orange-400 rounded-sm max-md:px-5"
          >
            <span className="flex-grow my-auto">
              пОЛУЧАТЬ РАССЫЛКУ
            </span>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/229f0f0e6aee1b2fa976a6842eda4e9b570ee38142380e004cac0094f4aae2f0?apiKey=49819ad3d25f4b5f837dad19f5421852&"
              alt=""
              className="my-auto w-5 aspect-square"
            />
          </button>
        </div>
        <img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d0c3d197ddcb05465705b0133602756038ed98b998777208d4054873ac9f18b2?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d0c3d197ddcb05465705b0133602756038ed98b998777208d4054873ac9f18b2?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d0c3d197ddcb05465705b0133602756038ed98b998777208d4054873ac9f18b2?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d0c3d197ddcb05465705b0133602756038ed98b998777208d4054873ac9f18b2?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d0c3d197ddcb05465705b0133602756038ed98b998777208d4054873ac9f18b2?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d0c3d197ddcb05465705b0133602756038ed98b998777208d4054873ac9f18b2?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d0c3d197ddcb05465705b0133602756038ed98b998777208d4054873ac9f18b2?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d0c3d197ddcb05465705b0133602756038ed98b998777208d4054873ac9f18b2?apiKey=49819ad3d25f4b5f837dad19f5421852&"className="mt-8 max-w-full aspect-[6.67] w-[1073px]"
          alt=""
        />
      </div>
    </header>
  );
}
export default SubscriptionFooter