import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

function SwiperHotelType() {
  return (
    <>
      <section className="flex justify-center items-center px-16 py-12 w-full max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col my-6 w-full max-w-[1362px] max-md:max-w-full p-8">
          <header className="flex gap-5 justify-between px-px w-full font-semibold max-md:flex-wrap max-md:max-w-full pb-20">
            <h1 className="flex-auto text-2xl leading-8 text-zinc-900 text-left">
              Поиск по типу размещения
            </h1>
            <div className="flex gap-2 justify-between text-sm leading-5 text-sky-400 whitespace-nowrap bg-white">
              <a href="#" className="grow">
                Browse All Product
              </a>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/7eb3f5f174abced25d2228ee5474be3ef833aa7325e339e9587b04f178e8f5c3?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                className="w-5 aspect-square"
                alt="Product Image"
              />
            </div>
          </header>
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              300: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
              1320: {
                slidesPerView: 6,
                spaceBetween: 40,
              },
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <form className="flex gap-5 justify-between px-px mt-16 text-base font-medium leading-6 whitespace-nowrap text-neutral-900 max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
              <div className="flex flex-col flex-1">
                <SwiperSlide>
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                    className="self-center aspect-[1.11] w-[200px]"
                    alt="Villa Image"
                  />
                  <div className="mt-2">Вилла</div>
                </SwiperSlide>
              </div>
              <div className="flex flex-col flex-1">
                <SwiperSlide>
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                    className="self-center aspect-[1.11] w-[200px]"
                    alt="Villa Image"
                  />
                  <div className="mt-2">Вилла</div>
                </SwiperSlide>
              </div>
              <div className="flex flex-col flex-1">
                <SwiperSlide>
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                    className="self-center aspect-[1.11] w-[200px]"
                    alt="Villa Image"
                  />
                  <div className="mt-2">Вилла</div>
                </SwiperSlide>
              </div>
              <div className="flex flex-col flex-1">
                <SwiperSlide>
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                    className="self-center aspect-[1.11] w-[200px]"
                    alt="Villa Image"
                  />
                  <div className="mt-2">Вилла</div>
                </SwiperSlide>
              </div>
              <div className="flex flex-col flex-1">
                <SwiperSlide>
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                    className="self-center aspect-[1.11] w-[200px]"
                    alt="Villa Image"
                  />
                  <div className="mt-2">Вилла</div>
                </SwiperSlide>
              </div>
              <div className="flex flex-col flex-1">
                <SwiperSlide>
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                    className="self-center aspect-[1.11] w-[200px]"
                    alt="Villa Image"
                  />
                  <div className="mt-2">Вилла</div>
                </SwiperSlide>
              </div>
              <div className="flex flex-col flex-1">
                <SwiperSlide>
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                    className="self-center aspect-[1.11] w-[200px]"
                    alt="Villa Image"
                  />
                  <div className="mt-2">Вилла</div>
                </SwiperSlide>
              </div>
              <div className="flex flex-col flex-1">
                <SwiperSlide>
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                    className="self-center aspect-[1.11] w-[200px]"
                    alt="Villa Image"
                  />
                  <div className="mt-2">Вилла</div>
                </SwiperSlide>
              </div>
              <div className="flex flex-col flex-1">
                <SwiperSlide>
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                    className="self-center aspect-[1.11] w-[200px]"
                    alt="Villa Image"
                  />
                  <div className="mt-2">Вилла</div>
                </SwiperSlide>
              </div>
              <div className="flex flex-col flex-1">
                <SwiperSlide>
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                    className="self-center aspect-[1.11] w-[200px]"
                    alt="Villa Image"
                  />
                  <div className="mt-2">Вилла</div>
                </SwiperSlide>
              </div>
              <div className="flex flex-col flex-1">
                <SwiperSlide>
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                    className="self-center aspect-[1.11] w-[200px]"
                    alt="Villa Image"
                  />
                  <div className="mt-2">Вилла</div>
                </SwiperSlide>
              </div>
              <div className="flex flex-col flex-1">
                <SwiperSlide>
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c6912fb0308f0f46a6cbc0273a01515b48bd2a00d58228002097d6aa5dc618f4?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                    className="self-center aspect-[1.11] w-[200px]"
                    alt="Villa Image"
                  />
                  <div className="mt-2">Вилла</div>
                </SwiperSlide>
              </div>
            </form>
          </Swiper>
        </div>
      </section>
    </>
  );
}
export default SwiperHotelType;
