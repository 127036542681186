import VectorImage from '../media/Vector.svg';
import React from "react";
import { useNavigate } from "react-router-dom";



function PasswordResetSuccess() {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/login")
    }
    return (
        <div className="flex-col overflow-hidden relative flex min-h-[1080px] justify-center items-center px-16 max-md:px-5">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/fb7bd6fb492c9fdd0383c9553a860c8543065ab941c68d17dc967ca141d2defd?apiKey=49819ad3d25f4b5f837dad19f5421852&" className="absolute object-cover object-center inset-0 size-full" />

            <form className="relative shadow-2xl backdrop-blur-[12.300000190734863px] bg-white bg-opacity-50 flex w-[992px] max-w-full flex-col pl-20 pr-10 pt-8 pb-12 items-center max-md:px-5">
                <img
                    loading="lazy"
                    src={VectorImage}
                    className="aspect-square object-contain w-4"
                    style={{ alignSelf: "end" }}
                />
                <header className="text-slate-900 text-center text-3xl font-bold mt-64 max-md:max-w-full max-md:mt-10">
                    Вы успешно восстановили пароль!
                </header>

                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/3bbdc3618b37e4921617f7214d26a6c6cb97ffdf0571517f09e4a46c7f78a815?apiKey=49819ad3d25f4b5f837dad19f5421852&" className="aspect-square object-contain object-center w-[57px] self-center mt-32 max-md:mt-10" />

                <button
                    className="text-zinc-50 text-xl font-medium bg-slate-900 w-[720px] max-w-full justify-center items-center mt-10 mb-64 px-16 py-7 rounded-[80px] max-md:mb-10 max-md:px-5"
                    aria-label="Продолжить"
                    onClick={handleClick}
                >
                    Продолжить
                </button>
            </form>
        </div>
    );
}
export default PasswordResetSuccess