import "./App.css";
import Home from "./Home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Create from "./Components/Create";
import BlogDetail from "./ProductDetail/BlogDetail";
import Login from "./Authorization/Login";
import { Navigation } from "./TestDev/Navigation";
import Signup from "./Authorization/Signup";
import Activate from "./Authorization/Activate";
import { Provider } from "react-redux";
import store from "./Store";
import Layout from "./hocs/Layout";
import ResetPassword from "./Authorization/ResetPassword";
import ResetPasswordConfirm from "./Authorization/ResetPasswordConfirm";
import VerificationComponent from "./Authorization/SignUpSuccess";
import NotFoundPage from "./404";
import PasswordResetSuccess from "./Authorization/PasswordResetSuccess";
import Navbar from "./Navbar/Navbar";
import SearchResultsPage from "./ProductList/SearchResultsPage";
import HotelDetail from "./ProductDetail/HotelDetail";
import { CartProvider } from "./ProductDetail/CartContext";

function App() {
   const title = 'W T N B';

  return (
    <Provider store={store}>
      <CartProvider>
        <Router>
          <Layout>
            <div className="App">
              <div className="content">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/create" element={<Create />} />
                  <Route path="/hotel/:id" element={<HotelDetail />} />
                  <Route path={"/login"} element={<Login />} />
                  <Route path={"/Navbar"} element={<Navigation />} />
                  <Route path={"/sign-up"} element={<Signup />} />
                  <Route path="/activate/:uid/:token" element={<Activate />} />
                  <Route path={"/reset-password"} element={<ResetPassword />} />
                  <Route
                    path="/password/reset/confirm/:uid/:token"
                    element={<ResetPasswordConfirm />}
                  />
                  <Route
                    path="/registration-success"
                    element={<VerificationComponent />}
                  />
                  <Route path="/404" element={<NotFoundPage />} />
                  <Route
                    path="/Password-Reset-Success"
                    element={<PasswordResetSuccess />}
                  />
                  <Route
                    path="/search-results"
                    element={<SearchResultsPage />}
                  />
                </Routes>
              </div>
            </div>
          </Layout>
        </Router>
      </CartProvider>
    </Provider>
  );
}

export default App;
