import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../Actions/auth";

const Login = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate(); // Hook for navigation

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    // This useEffect will trigger when isAuthenticated changes
    if (isAuthenticated) {
      navigate("/"); // Navigate to the home page
    }
  }, [isAuthenticated, navigate]);

  const onSubmit = async (e) => {
    e.preventDefault();

    await login(email, password);
  };

  //Is user authed > redir to home
  return (
    <div className="flex-col overflow-hidden relative flex min-h-[1080px] justify-center items-center px-16 max-md:px-5">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0b9864c6bbf285c5cc7758043ac4f2a8cf2366f7208147926c4bb55b7fd7248e?apiKey=49819ad3d25f4b5f837dad19f5421852&"
        className="absolute object-cover object-right inset-0 size-full"
      />

      <div className="relative shadow-2xl backdrop-blur-[12.300000190734863px] bg-white bg-opacity-50 flex w-[992px] max-w-full justify-center gap-5 pl-20 pr-10 pb-12 items-start max-md:flex-wrap max-md:px-5">
        <header className="flex flex-col items-center max-md:max-w-full">
          <div className="shadow-lg flex w-[168px] max-w-full flex-col items-center pt-12 pb-4 px-8 rounded-b-[20px] max-md:px-5">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/e6fd65f321f0a68814275560bb36b65de78f2412ab3c6a233b1d16faac1e2653?apiKey=49819ad3d25f4b5f837dad19f5421852&"
              className="aspect-[0.85] object-contain object-center w-full mt-20 max-md:mt-10"
            />
          </div>
          <h1 className="text-slate-900 text-center text-3xl font-bold whitespace-nowrap mt-9">
            Рады Вас видеть!
          </h1>
          <div className="text-slate-900 text-center text-base font-medium whitespace-nowrap mt-10">
            <span className="text-slate-900">Первый раз?</span>{" "}
            <span className="font-bold text-slate-900">
              <Link to={"/sign-up"}> Срочно регистрируйстесь!</Link>
            </span>
          </div>
          <form action="" onSubmit={(e) => onSubmit(e)}>
            <div className="text-white text-opacity-90 text-sm bg-gray-700 w-[485px] max-w-full justify-center mt-10 px-12 py-5 rounded-[80px] items-center max-md:px-5">
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <input
                type="email"
                name={"email"}
                value={email}
                placeholder={"Email"}
                className="form-control w-full outline-none bg-transparent"
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="bg-gray-700 flex w-[485px] max-w-full items-stretch justify-between gap-5 mt-10 px-9 py-4 rounded-[80px] max-md:flex-wrap max-md:px-5">
              <div className="text-white text-opacity-90 text-sm grow shrink basis-auto">
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  name={"password"}
                  value={password}
                  placeholder={"Password"}
                  className="form-control w-full outline-none bg-transparent"
                  onChange={(e) => onChange(e)}
                  required
                // minLength={6}
                />
              </div>
              <button onClick={handleShowPassword}>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/2e578726495071397eec256a9cdafcebacc5787369e9aff3e10256b793f26e66?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                className="aspect-[1.19] object-contain object-center w-[19px] fill-white fill-opacity-80 shrink-0"
                alt="arrow"
                
              />
              </button>
            </div>
            <div className="text-slate-900 text-center text-base font-bold whitespace-nowrap mt-10 ">
              <Link to={"/reset-password"}> Забыли пароль?</Link>
            </div>
            <button
              type={"submit"}
              className="text-zinc-50 text-xl font-medium bg-slate-900 self-stretch justify-center items-center mt-10 px-16 py-7 rounded-[80px] max-md:max-w-full max-md:px-5"
            >
              Продолжить
            </button>

          </form>

        </header>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/cf39d45f0b22e0459e64ce8aca168cdbde65f9e59a76953709f1418550d248ef?apiKey=49819ad3d25f4b5f837dad19f5421852&"
          className="aspect-square object-contain object-center w-6 shrink-0 mt-8"
          alt="logo"
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);
