import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import StarRating from "./StarRating";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import './styles.css';

// import required modules
import { Pagination } from "swiper/modules";
const MAX_DESCRIPTION_LENGTH = 300;

const swiper = ({ blogs: hotels, title }) => {
  const truncateDescription = (description) => {
    if (description.length > MAX_DESCRIPTION_LENGTH) {
      return `${description.substring(0, MAX_DESCRIPTION_LENGTH)}...`;
    }
    return description;
  };
  return (
    <>
      <section className="flex justify-center items-center  w-full max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col  w-full max-w-[1362px] max-md:max-w-full p-8">
          <header className="flex gap-5 justify-between px-px w-full font-semibold max-md:flex-wrap max-md:max-w-full">
            <h1 className="flex-auto text-2xl leading-8 text-zinc-900 text-left">
              Популярные направления
            </h1>
            <div className="flex gap-2 justify-between text-sm leading-5 text-sky-400 whitespace-nowrap bg-white hidden">
              <a href="#" className="grow">
                Browse All Product
              </a>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/7eb3f5f174abced25d2228ee5474be3ef833aa7325e339e9587b04f178e8f5c3?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                className="w-5 aspect-square"
                alt="Product Image"
              />
            </div>
          </header>
        </div>
      </section>
      <div className="flex flex-col justify-center">
        <div className="flex gap-8 self-center   mt-6 max-w-full  w-[1320px] max-md:flex-wrap ">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 1,
                spaceBetween: 50,
              },
              1320: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {hotels.map((hotel) => (
              <SwiperSlide>
                <div className="grow max-md:mt-8 max-md:max-w-full rounded-md shadow-sm sm:pl-1 bg-white">
                  <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
                    <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                      {hotel.cover_image ? (
                        <img
                          src={hotel.cover_image}
                          alt={hotel.base_hotel.name}
                          className="grow w-full rounded-lg aspect-[0.83] p-1"
                        />
                      ) : (
                        <img
                          src={window.location.origin + "/img-not-found.png"}
                          alt={hotel.base_hotel.name}
                          className="grow w-full rounded-lg aspect-[0.83]"
                        />
                      )}
                    </div>

                    <div className="flex flex-col pr-4 w-6/12 max-md:ml-0 max-md:w-full">
                      <div className="flex flex-col grow pt-2 text-left">
                        <header className="text-2xl font-semibold leading-9 whitespace-nowrap text-neutral-900 left">
                          {hotel.base_hotel.name}
                        </header>
                        <div className="mt-6 text-base leading-6 text-neutral-400">
                          {truncateDescription(hotel.description)}
                        </div>
                        <div className="mt-16 text-base font-medium leading-6 text-neutral-900 max-md:mt-10">
                          {hotel.hotel_features
                            .slice(0, 3)
                            .map((feature, index, array) =>
                              index === array.length - 1
                                ? feature.name + "."
                                : feature.name + ", "
                            )}
                        </div>
                        <div className="flex gap-1 justify-between mt-3">
                          <div className="flex gap-1 justify-between text-sm leading-5 text-neutral-400 items-center">
                            <a href="#" className=" w-4 aspect-square ">
                              <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/637744247a255a9bf984416b4c05957f2b3495bd35bbea713a11ce971cab635d?apiKey=49819ad3d25f4b5f837dad19f5421852&"
                                alt="england"
                              />
                            </a>
                            <div className="flex-auto">
                              {hotel.location_city.country.name},{" "}
                              {hotel.location_city.name}
                            </div>
                          </div>
                          <div className="flex gap-0.5 self-center pr-4">
                            <StarRating
                              starCount={hotel.stars}
                              starColor="#FFA800"
                              className=" w-4 aspect-square"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex gap-5 text-left justify-between pr-20 mt-6 text-xs leading-4 whitespace-nowrap max-md:pr-5">
                        <div className="flex gap-1 justify-between">
                          <div className="grow text-black font-normal	text-base text-left">
                            {/* Hotel Rating: */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};
export default swiper;
