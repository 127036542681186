import React, { useState } from "react";
import axios from "axios";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [submissionStatus, setSubmissionStatus] = useState({
    message: "",
    type: "", // success or error
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/contact_form/contact/`,
        formData
      );
      
      // Optionally reset form after successful submission
      setFormData({ name: "", email: "", phone: "", message: "" });
      setSubmissionStatus({
        message: "Form submitted successfully!",
        type: "success",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      setSubmissionStatus({
        message: "Error submitting form. Please try again later.",
        type: "error",
      });
      // Handle error
    }
  };
  const closePopup = () => {
    setSubmissionStatus({ message: "", type: "" });
  };

  return (
    <section id="contact_form" className="flex justify-center items-center px-16 py-12 w-full max-md:px-5 max-md:max-w-full bg-gray-100">
      <div className="flex flex-col my-6 w-full max-w-[1362px] max-md:max-w-full p-8 text-left">
        {submissionStatus.message && (
          <div
            className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 bg-white border border-gray-300 rounded-lg shadow-lg ${
              submissionStatus.type === "success"
                ? "border-green-500"
                : "border-red-500"
            }`}
          >
            <p className="mb-2 text-lg font-semibold">
              {submissionStatus.message}
            </p>
            <button
              className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
              onClick={closePopup}
            >
              Close
            </button>
          </div>
        )}
        <div class="flex items-center justify-center p-12">
          <div class="mx-auto w-full max-w-[550px]">
            <form onSubmit={handleSubmit}>
              <div class="mb-5">
                <label
                  for="name"
                  class="mb-3 block text-base font-medium text-[#07074D]"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Full Name"
                  value={formData.name}
                  onChange={handleChange}
                  class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                />
              </div>
              <div class="mb-5">
                <label
                  for="email"
                  class="mb-3 block text-base font-medium text-[#07074D]"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                />
              </div>
              <div class="mb-5">
                <label
                  for="subject"
                  class="mb-3 block text-base font-medium text-[#07074D]"
                >
                  Phone
                </label>
                <input
                  type="phone"
                  name="phone"
                  id="phone"
                  placeholder="+1-202-555-0126"
                  value={formData.phone}
                  onChange={handleChange}
                  class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                />
              </div>
              <div class="mb-5">
                <label
                  for="message"
                  class="mb-3 block text-base font-medium text-[#07074D]"
                >
                  Message
                </label>
                <textarea
                  rows="4"
                  name="message"
                  id="message"
                  placeholder="Type your message"
                  value={formData.message}
                  onChange={handleChange}
                  class="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                ></textarea>
              </div>

              <button class="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-base font-semibold text-white outline-none">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
