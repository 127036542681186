import { useState } from "react";

const Create = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [author, setAuthor] = useState('');
    const [body, setBody] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        const blog = { title, author };
        setIsLoading(true);
        fetch('http://localhost:8000/blogs', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(blog)

        }).then(() => {
            setIsLoading(false)
        })
    }

    return (
        <div className="create">
            <h2>Create a new blog!</h2>
            <form onSubmit={handleSubmit}>
                <label>Blog Title:</label>
                <input type="text" required value={title} onChange={(e) => setTitle(e.target.value)} />
                <label>Blog Author:</label>
                <input type="text" required value={author} onChange={(e) => setAuthor(e.target.value)} />
                <label>Blog Content:</label>
                <textarea required value={body} onChange={(e) => setBody(e.target.value)}></textarea>
                {!isLoading && <button style={{ background: "blue", marginTop: "20px" }}>Add Blog</button>}
                {isLoading && <button disabled style={{ background: "blue", marginTop: "20px" }}>Adding your blog...</button>}

            </form>
        </div>
    );
}

export default Create;
