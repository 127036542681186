import BlogList from "../ProductList/BlogList";
import useFetch from "../Components/useFetch";
import TourPacksOffers from "../ProductList/TourPacksOffers";
import { Link } from "react-router-dom";
import Search from "../SearchBar/Search";
import Header from "../Components/Header/Header";
import PopularDestinations from "../ProductList/ProductList";
import Swiper from "../ProductList/swiper";
import SwiperHotelType from "../ProductList/SwiperHotelType";
import SwiperActivity from "../ProductList/SwiperActivity";
import SubscriptionFooter from "../Components/Footer/SubscriptionFooter";
import MainFooter from "../Components/Footer/MainFooter";
import AutherFooter from "../Components/Footer/AutherFooter";
import ContactUS from "../Components/Footer/ContactUs";
import AboutUs from "../Components/Footer/AboutUs";
import OurTeam from "../Components/Footer/OurTeam";
import Features from "../Components/Footer/Features";
import ContactForm from "../Components/Footer/ContactForm";
import Gallary from "../Components/Footer/Gallary";

const Home = () => {
  const {
    data: blogs,
    isLoading,
    error,
  } = useFetch(`${process.env.REACT_APP_API_URL}/api/v1/hotels/`);
  const { data: TourOffers } = useFetch(
    `${process.env.REACT_APP_API_URL}/api/v1/tours/`
  );

  return (
    <div>
      <Header />
      {error && <div>{error}</div>}
      {isLoading && (
        <div className="loading-preview">
          <div id="loader"></div>
          <h2>Loading...</h2>
        </div>
      )}
      {blogs && <Swiper blogs={blogs} title="All Blogs!" />}
      {/* {blogs && <PopularDestinations blogs={blogs} title="All Blogs!" />} */}
      {/* <SwiperHotelType /> */}
      {TourOffers && <SwiperActivity TourOffers={TourOffers}></SwiperActivity>}
      {/* <SwiperActivity /> */}
      <ContactUS />
      <AboutUs />
      <Gallary />
      <OurTeam />
      <Features />
      <ContactForm />
      {/* <SubscriptionFooter /> */}
      <MainFooter />
      <AutherFooter />
    </div>
    // <div className="home">
    //   <div
    //     className={"nav-banner"}
    //     style={{
    //       backgroundImage: `url(${window.location.origin}/palma_back.png)`,
    //     }}
    //   >
    //     <Search></Search>
    //   </div>
    //   {error && <div>{error}</div>}
    //   {isLoading && (
    //     <div className="loading-preview">
    //       <div id="loader"></div>
    //       <h2>Loading...</h2>
    //     </div>
    //   )}
    //   {blogs && <BlogList blogs={blogs} title="All Blogs!" />}
    //   <TourPacksOffers></TourPacksOffers>
    //   {/* <BlogList blogs={blogs.filter((blog) => blog.author === "Mario")} title="Mario" /> */}

    //   {/* <h2>Homepage</h2>
    //         <button type="button" onClick={handleClick}>Click Me</button>
    //         <button type="button" onClick={()=>{
    //             handleClickAgain('Mario')
    //         }}>Click me again</button> */}
    // </div>
  );
};

export default Home;
